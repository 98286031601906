import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";

class EditAluno extends React.Component {
  state = {
    email: "",
    password: "",
    name: "",
    id: "",
    hidden: true,
    loading: false,
    loadingEdit: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.makeid = this.makeid.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await api
      .get(`/users/${this.props.match.params.id}`, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          type: res.data.type,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            loading: false,
            /*message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();*/
          }
        );
      });
  }

  onChangeName(e) {
    e.preventDefault();
    this.setState({
      name: e.target.value
    });
  }

  onChangeType(e) {
    e.preventDefault();
    this.setState({
      type: e.target.value
    });
  }

  onChangeEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    e.preventDefault();
    this.setState({
      password: e.target.value
    });
  }

  makeid() {
    var text = "";
    text =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    this.setState({
      password: text
    });

    console.log(text);
    return text;
  }

  toggleShow() {
    this.setState({
      hidden: !this.state.hidden
    });
  }

  async handleSubmit() {
    this.setState({
      loadingEdit: true
    });
    const data = new FormData();

    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("type", "student");
    data.append("id", this.state.id);
    await api
      .post(`users/${this.state.id}/update`, data, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          loadingEdit: false
        });
        this.props.history.push("/alunos", true);
      })
      .catch(error => {
        this.setState(
          {
            loadingEdit: false,
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  render() {
    const { loading, loadingEdit } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title="Editar aluno"
            subtitle="Detalhe do cadastro"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card>
              {loading === false ? (
                <>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <label htmlFor="username" style={{ fontWeight: "600" }}>
                          Nome
                        </label>
                        <input
                          type="text"
                          value={this.state.name}
                          onChange={this.onChangeName}
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="username" style={{ fontWeight: "600" }}>
                          E-mail
                        </label>
                        <input
                          type="email"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                          className="form-control"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="username" style={{ fontWeight: "600" }}>
                          Senha
                        </label>
                        <InputGroup>
                          <InputGroupAddon type="append">
                            <Button theme="success" onClick={this.toggleShow}>
                              <i
                                className="material-icons"
                                style={{ fontSize: "23px" }}
                              >
                                visibility
                              </i>
                            </Button>
                          </InputGroupAddon>
                          <FormInput
                            className="form-control"
                            id="username"
                            type="password"
                            value={this.state.password}
                            type={this.state.hidden ? "password" : "text"}
                          />
                          <InputGroupAddon type="append">
                            <Button theme="primary" onClick={this.makeid}>
                              Gerar senha
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter
                    style={{ borderTop: "1px #eee solid" }}
                    align="right"
                  >
                    <Button
                      size="sm"
                      onClick={this.handleSubmit}
                      disabled={loadingEdit === true}
                    >
                      {loadingEdit === true && (
                        <>
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{ paddingLeft: "8px" }}
                            >
                              <CircleSpinner
                                size={20}
                                color="#fff"
                                loading={loadingEdit}
                              ></CircleSpinner>
                            </div>
                            <div className="col">
                              <span
                                style={{
                                  paddingTop: "4px",
                                  verticalAlign: "middle"
                                }}
                              >
                                Editando cadastro...
                              </span>
                            </div>
                          </div>
                        </>
                      )}{" "}
                      {loadingEdit === false && <>Editar aluno</>}
                    </Button>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(EditAluno);
