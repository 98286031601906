import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge
} from "shards-react";

import Chart from "../../../../utils/chart";

class GraficoRealizacao extends React.Component {
  state = {
    title: "Prova realizada por"
  };
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const chartConfig = {
      type: "pie",
      data: {
        datasets: [
          {
            hoverBorderColor: "#ffffff",
            data: [this.props.realizado, this.props.naorealizado],
            backgroundColor: ["rgba(0,123,255,0.9)", "rgba(0,123,255,0.3)"]
          }
        ],
        labels: ["Realizado", "Não realizado"]
      },
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        }
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    const { title } = this.state;
    return (
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody
          className="d-flex py-0"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
      </Card>
    );
  }
}

export default GraficoRealizacao;
