import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormSelect,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";

class CadastrarAluno extends React.Component {
  state = {
    email: "",
    password: "",
    nome: "",
    hidden: true,
    loading: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.makeid = this.makeid.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  onChangeNome(e) {
    e.preventDefault();
    this.setState({
      nome: e.target.value
    });
  }

  onChangeEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  makeid() {
    var text = "";
    text =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);

    this.setState({
      password: text
    });

    console.log(text);
    return text;
  }

  async handleSubmit() {
    this.setState({
      loading: true
    });
    const data = new FormData();
    data.append("name", this.state.nome);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("type", "student");
    await api
      .post("users/register-student", data)
      .then(res => {
        this.setState({
          loading: false
        });
        this.props.history.push("/alunos");
      })
      .catch(error => {
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger",
            loading: false
          },
          () => {
            this.showAlert();
          }
        );
        console.log(error);
      });
  }

  toggleShow() {
    this.setState({
      hidden: !this.state.hidden
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title="Cadastrar aluno"
            subtitle="Detalhe do cadastro"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card>
              <CardBody>
                <Form>
                  <FormGroup>
                    <label htmlFor="username" style={{ fontWeight: "600" }}>
                      Nome
                    </label>
                    <FormInput
                      className="form-control"
                      id="username"
                      type="text"
                      onChange={this.onChangeNome}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="username" style={{ fontWeight: "600" }}>
                      E-mail
                    </label>
                    <FormInput
                      className="form-control"
                      id="username"
                      type="email"
                      onChange={this.onChangeEmail}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="username" style={{ fontWeight: "600" }}>
                      Senha
                    </label>
                    <InputGroup>
                      <InputGroupAddon type="append">
                        <Button theme="success" onClick={this.toggleShow}>
                          <i
                            className="material-icons"
                            style={{ fontSize: "23px" }}
                          >
                            visibility
                          </i>
                        </Button>
                      </InputGroupAddon>
                      <FormInput
                        className="form-control"
                        id="username"
                        type="password"
                        value={this.state.password}
                        type={this.state.hidden ? "password" : "text"}
                      />
                      <InputGroupAddon type="append">
                        <Button theme="primary" onClick={this.makeid}>
                          Gerar senha
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button
                  size="sm"
                  onClick={this.handleSubmit}
                  disabled={loading === true}
                >
                  {loading === true && (
                    <>
                      <div className="row">
                        <div
                          className="col-auto"
                          style={{ paddingLeft: "8px" }}
                        >
                          <CircleSpinner
                            size={20}
                            color="#fff"
                            loading={loading}
                          ></CircleSpinner>
                        </div>
                        <div className="col">
                          <span
                            style={{
                              paddingTop: "4px",
                              verticalAlign: "middle"
                            }}
                          >
                            Cadastrando aluno...
                          </span>
                        </div>
                      </div>
                    </>
                  )}{" "}
                  {loading === false && (
                    <>
                      <i className="far fa-bookmark mr-1" />
                      Cadastrar aluno
                    </>
                  )}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CadastrarAluno;
