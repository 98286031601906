import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import Editor from "../../components/add-new-post/Editor";
import PageTitle from "../../components/common/PageTitle";
import api from "../../services/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "../Turmas/styles.css";
import { Link, useHistory, withRouter } from "react-router-dom";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../Categorias/styles.module.css";
import { roundToNearestMinutes } from "date-fns/esm";
class Provas extends React.Component {
  state = {
    provas: [],
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
    filteredData: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    open: false,
    alunos: [],
    selectedOption: null,
    idProvaSelecionada: "",
    loading: false,
    loadingProvas: false,
    loadingProvaReprovado: false
  };

  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.setTimezone = this.setTimezone.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    this.setState({
      open: !this.state.open,
      idProvaSelecionada: id
    });
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.provas.filter(el =>
        el.titulo.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  async receivedData() {
    this.setState({
      loadingProvas: true
    });
    await api
      .get("provas")
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          provas: slice,
          loadingProvas: false
        });

        this.setState({
          filteredData: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        this.setState(
          {
            //message: "Ops! Algo saiu errado, tente novamente.",
            //theme: "danger",
            loadingProvas: false
          }/*,
          () => {
            this.showAlert();
          }*/
        );
      });
  }

  async componentDidMount() {
    this.receivedData();
    if (this.props.location.state === true) {
      this.setState({
        message: "Prova cadastrada com sucesso.",
        theme: "success"
      });
      this.showAlert();
    } else if (this.props.location.state === false) {
      this.setState({
        message: "Prova alterada com sucesso.",
        theme: "success"
      });
      this.showAlert();
    }

    api
      .get("users/list-students-select")
      .then(res => {
        this.setState({
          alunos: res.data
        });
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  setTimezone(e) {
    moment.locale("pt-br");
    return moment(e).format("DD/MM/YYYY");
  }

  async gerarProvaReprovado(id) {
    this.setState({
      loadingProvaReprovado: true
    });
    const data = new FormData();
    data.append("prova_id", id);
    await api
      .post("/provas/gerar-prova-reprovado", data)
      .then(res => {
        this.setState({
          loadingProvaReprovado: false
        });
        this.props.history.push("/provas-reprovados", true);
      })
      .catch(error => {
        this.setState(
          {
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger",
            loadingProvaReprovado: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  showAlert() {
    console.log("entrei");
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    console.log("Prova: " + this.state.idProvaSelecionada);
  };

  async handleSubmit() {
    this.setState({
      loading: true
    });
    const data = new FormData();
    data.append("aluno", JSON.stringify(this.state.selectedOption));
    data.append("prova_id", this.state.idProvaSelecionada);

    await api
      .post("/provas/user-prova", data)
      .then(res => {
        this.setState({
          open: false,
          loading: false
        }, () => {
          this.setState({
            message: "Usuário convidado para realização da prova com sucesso.",
            theme: "success",
          },  () => {
            this.showAlert()
          })
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false
        });
      });
  }

  render() {
    const {
      provas,
      open,
      loading,
      loadingProvas,
      loadingProvaReprovado
    } = this.state;
    const { filteredData } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Provas"
            subtitle="Provas cadastradas no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6 className="m-0">Provas</h6>
                  </div>
                  <div className="col-md-8 col-8 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <InputGroup size="md" seamless>
                          <InputGroupAddon type="prepend">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            placeholder="Pesquisar pelo título da prova"
                            style={{ fontSize: "14px" }}
                            onChange={this.handleSearchChange}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6 col-6">
                        <Link
                          to="/prova-cadastro"
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="material-icons">add_circle_outline</i>{" "}
                          Cadastrar nova prova
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loadingProvas === false ? (
                <>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Título
                          </th>
                          <th scope="col" className="border-0">
                            Data de criação
                          </th>
                          <th scope="col" className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((prova, index) => (
                          <tr key={prova.id}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/prova/${prova.id}`,
                                  prova: {
                                    titulo: prova.titulo,
                                    id: prova.id,
                                    dataCadastro: prova.dataCadastro,
                                    duracaoMaxima: prova.duracaoMaxima,
                                    tituloBotao: prova.tituloBotao,
                                    texto: prova.texto
                                  }
                                }}
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "Roboto"
                                }}
                              >
                                {prova.titulo}
                              </Link>
                            </td>
                            <td>{this.setTimezone(prova.dataCadastro)}</td>
                            <td className="d-flex justify-content-end">
                              <Button
                                onClick={() => this.toggle(prova.id)}
                                style={{ marginRight: "15px" }}
                              >
                                Convidar aluno
                              </Button>
                              <Button
                                theme="danger"
                                style={{ marginRight: "20px" }}
                                onClick={() =>
                                  this.gerarProvaReprovado(prova.id)
                                }
                                disabled={loadingProvaReprovado === true}
                              >
                                {loadingProvaReprovado === true && (
                                  <>
                                    <div className="row">
                                      <div
                                        className="col-auto"
                                        style={{ paddingLeft: "8px" }}
                                      >
                                        <CircleSpinner
                                          size={20}
                                          color="#fff"
                                          loading={loadingProvaReprovado}
                                        ></CircleSpinner>
                                      </div>
                                      <div className="col">
                                        <span
                                          style={{
                                            paddingTop: "4px",
                                            verticalAlign: "middle"
                                          }}
                                        >
                                          Gerando prova...
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}{" "}
                                {loadingProvaReprovado === false && (
                                  <>Gerar prova reprovado</>
                                )}
                              </Button>
                              <Link
                                to={{
                                  pathname: `prova-edit/${prova.id}`,
                                  prova: {
                                    titulo: prova.titulo,
                                    id: prova.id,
                                    dataCadastro: prova.dataCadastro,
                                    duracaoMaxima: prova.duracaoMaxima,
                                    tituloBotao: prova.tituloBotao,
                                    texto: prova.texto
                                  }
                                }}
                                className="btn btn-white btn-sm"
                                type="button"
                              >
                                <i className="far fa-bookmark mr-1" /> Editar
                                prova
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter style={{ borderTop: "1px #eee solid" }}>
                    <div
                      align="center"
                      className="d-flex justify-content-center"
                    >
                      <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </div>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>

        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>Convidar usuário para realizar prova</ModalHeader>
          <ModalBody>
            <label style={{ fontWeight: "600" }}>Selecionar usuário</label>
            <Select onChange={this.handleChange} options={this.state.alunos} />
          </ModalBody>
          <ModalFooter align="right">
            <Button
              onClick={() => this.handleSubmit()}
              disabled={loading === true}
            >
              {loading === true && (
                <>
                  <div className="row">
                    <div className="col-auto" style={{ paddingLeft: "8px" }}>
                      <CircleSpinner
                        size={20}
                        color="#fff"
                        loading={loading}
                      ></CircleSpinner>
                    </div>
                    <div className="col">
                      <span
                        style={{ paddingTop: "4px", verticalAlign: "middle" }}
                      >
                        Convidando aluno...
                      </span>
                    </div>
                  </div>
                </>
              )}{" "}
              {loading === false && <>Convidar aluno</>}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(Provas);
