import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle 
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";

class Documentacao extends React.Component {
  goToProvaTutorial() {
    this.props.history.push("/documentacao-provas");
  }

  goToCategoriaTutorial() {
    this.props.history.push("/documentacao-categorias");
  }

  goToUsuarioTutorial() {
    this.props.history.push("/documentacao-usuarios");
  }

  goToRealizacaoProvaProvaTutorial() {
    this.props.history.push("/documentacao-realizacao-prova");
  }

  goToProvaRealizacaoReprovadoTutorial() {
    this.props.history.push("/documentacao-realizacao-prova-reprovado")
  }

  goToEnvioDocTutorial() {
    this.props.history.push("/documentacao-realizacao-envio-doc")
  }

  goToDashboardTutorial() {
    this.props.history.push("/documentacao-dashboard")
  }

  goToProvaReprovadoTutorial() {
    this.props.history.push("/documentacao-provas-reprovados")
  }

  goToAlunosTutorial() {
    this.props.history.push("/documentacao-alunos")
  }


  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Documentação"
            subtitle="Documentação das funcionalidades administrativas do sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Gerenciamento de provas</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToProvaTutorial()}>
                  Leia mais &rarr;
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Gerenciamento de alunos</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToAlunosTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Gerenciamento de categorias</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToCategoriaTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Gerenciamento de usuários</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToUsuarioTutorial()}>
                  Leia mais &rarr;
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Gerenciamento de provas reprovadas</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToProvaReprovadoTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Dashboard</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToDashboardTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Documentação"
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Realização de prova</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToRealizacaoProvaProvaTutorial()}>
                  Leia mais &rarr;
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Realização 2° prova</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToProvaRealizacaoReprovadoTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="4" style={{marginTop: '15px'}}>
            <Card className="h-100">
              <CardBody>
                <CardTitle>Envio documentação</CardTitle>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
                <Button onClick={() => this.goToEnvioDocTutorial()}>Leia mais &rarr;</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(Documentacao);
