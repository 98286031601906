import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import "../styles.css";
 
class CategoriasDocumentacao extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Gerenciamento de Categorias"
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="8" md="8">
            <Accordion />
          </Col>
        </Row>
      </Container>
    );
  }
}

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          <li {...{ className: "accordion-list__item" }}>
            <AccordionItemCadastro />
            <AccordionItemListagem />
          </li>
        </ul>
      </div>
    );
  }
}

class AccordionItemListagem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>
              Listagem de categorias cadastradas
            </h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px", textAlign: "justify" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Para visualizar as categorias cadastradas, clique no menu{" "}
                <u>Categorias</u>. <br /><br /> Nesta aba é possível filtrar as categorias
                cadastradas pelo nome da categoria, no campo de busca.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class AccordionItemCadastro extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>Cadastrar categoria</h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px", textAlign: "justify" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Para realizar o cadastro de uma nova categoria, clique no menu{" "}
                <u>Categorias</u>.<br />
                <br />
                Clique no botão <u>Cadastrar nova categoria</u>.<br />
                <br />O formulário de cadastro de nova categoria possui dois campos:{" "}
                <i>Nome</i>.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CategoriasDocumentacao;
