import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import "../styles.css";

class RealizacaoProvaDocumentacao extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Realização de provas "
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12" md="12">
            <Accordion />
          </Col>
        </Row>
      </Container>
    );
  }
}

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          <li {...{ className: "accordion-list__item" }}>
            <AccordionItemListagem />
          </li>
        </ul>
      </div>
    );
  }
}

class AccordionItemListagem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>
              Realização de prova
            </h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Ao iniciar o sistema o usuário encontrará a página de login ao
                acesso ao sistema. O acesso deverá ser feito com as credenciais
                recebidas via e-mail (segunda imagem). <br />
                <br />
                <img
                  src="https://i.imgur.com/EtJTZbr.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                <img
                  src="https://i.imgur.com/TYIckqz.png"
                  className="img-fluid"
                />
                <br />
                <br />
                Após realizado o login, a tela exibirá o prazo máximo para
                efetuação da prova, caso o tempo seja excedido, será exibido um
                aviso (segunda imagem).
                <br />
                <br />
                <img src="https://i.imgur.com/XlszrOu.png" className="img-fluid" width="60%" /><br />
                <br />
                <img src="https://i.imgur.com/FGdcjlH.png" className="img-fluid" width="60%" /><br />
                <br />
                Avançando, o usuário encontrará os detalhes definidos da
                descrição da prova. Contemplando as regras de realização da
                prova.
                <br />
                <br />
                <img
                  src="https://i.imgur.com/e0cS6pP.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Clicando para iniciar a prova, serão definidas as seguintes
                regras:
                <br />
                <ul>
                  <li>Será exibida uma pergunta por página.</li>
                  <li>
                    O botão próxima questão ficará desabilitado enquanto não for
                    escolhida uma resposta.
                  </li>
                  <li>
                    A ordem das perguntas varia para cada prova e cada aluno
                    dentre as 150 cadastradas no sistema.
                  </li>
                  <li>
                    O contador começará a contar 60 minutos, sendo que ao chegar
                    a essa marca, ele redirecionará automaticamente para página
                    de resultado encerrando a prova.
                  </li>
                  <li>
                    O usuário não consegue pular uma pergunta, por isso ele não
                    consegue avançar pela paginação para perguntas que não forem
                    respondidas. Apenas consegue retornar a perguntas que já
                    foram respondidas.
                  </li>
                  <li>
                    A resposta escolhida pelo usuário ficará destacada em verde,
                    durante a prova ele consegue alterar a resposta.
                  </li>
                </ul>
                <br />
                <img
                  src="https://i.imgur.com/SVuvgYq.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Ao chegar na última pergunta (50) o botão "Finalizar prova" será
                exibido. <br />
                <br />
                <img
                  src="https://i.imgur.com/zEHjF6r.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Ao finalizar a prova, a tela de resultado será exibida.
                Contemplando os seguintes itens: número da pontuação, resultado
                (aprovado ou reprovado), questões e suas respostas escolhidas
                pelo aluno.
                <br />
                <br />
                <img
                  src="https://i.imgur.com/fOqyChc.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Caso o usuário seja aprovado, ele receberá automaticamente o
                e-mail com as instruções de envio da documentação: <br />
                <br />
                <img
                  src="https://i.imgur.com/CphZgIS.png"
                  className="img-fluid"
                />
                <br />
                <br />
                Após finalizado a prova e exibido o resultado, a dashboard do
                usuário contará com o prazo para envio da documentação (caso o
                usuário tenha sido aprovado na prova) e os detalhes da prova
                realizada. Caso o usuário tenha sido <i>reprovado</i> as
                questões e as respostas escolhidas <u>não</u> serão exibidas.
                <br /> <br />
                <img
                  src="https://i.imgur.com/Kqmgbky.png"
                  className="img-fluid"
                  width="60%"
                />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RealizacaoProvaDocumentacao;
