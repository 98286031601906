import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import "../styles.css";

class RealizacaoEnvioDocDocumentacao extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Realização envio de documentação"
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12" md="12">
            <Accordion />
          </Col>
        </Row>
      </Container>
    );
  }
}

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          <li {...{ className: "accordion-list__item" }}>
            <AccordionItemListagem />
          </li>
        </ul>
      </div>
    );
  }
}

class AccordionItemListagem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>
              Realização envio
            </h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Na dashboard do usuário, será exibido a data limite para envio
                da documentação. Caso o prazo seja excedido, será exibido a
                seguinte mensagem (segunda imagem).
                <br />
                <br />
                <img
                  src="https://i.imgur.com/HjdN5Tl.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                <img
                  src="https://i.imgur.com/ub2SFsf.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Optando por realizar o envio, o formulário será exibido. O
                formulário de envio possui as seguintes regras:
                <ul>
                  <li>
                    O arquivo do áudio deverá respeitar o limite entre 44
                    minutos e 55 minutos de duração. O formato deverá ser MP3.
                    Essas regras são verificadas pelo sistema ao realizar o
                    upload.
                  </li>
                  <li>
                    Os demais arquivos devem ser no formatos PDF ou DOC. Essas
                    regras são verificadas pelo sistema ao realizar o upload.
                  </li>
                </ul>
                <br />
                <img
                  src="https://i.imgur.com/93UbIKD.png"
                  className="img-fluid"
                  width="60%"
                />
                <br />
                <br />
                Após submetido a documentação, será exibido o status do envio da
                análise da documentação.
                <br />
                <br />
                <img
                  src="https://i.imgur.com/AX7cDtF.png"
                  className="img-fluid"
                  width="60%"
                />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RealizacaoEnvioDocDocumentacao;
