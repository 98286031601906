import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import { timing } from "react-ga";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import "../../../../assets/quill.css";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";
import ReactQuill from "react-quill";
import DocumentacaoEnvioReprovado from "./DocumentacaoEnvioReprovado";

class DocumentacaoPrimeiroEnvio extends React.Component {
  state = {
    resultado: null,
    open: false,
    descricao: "",
    consideracao: "",
    loading: false,
    loadingReprovado: false,
    loadingDocumentos: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loadingAddConsideracoes: false,
    documento: {},
    getConsideracao: {},
    openModal: false,
    idDocumento: ""
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setTimezone = this.setTimezone.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.onChangeConsideracao = this.onChangeConsideracao.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      openModal: !this.state.openModal
    });
  }

  onChangeConsideracao(e) {
    e.preventDefault();
    this.setState({
      consideracao: e.target.value
    });
  }

  handleQuillChange = value => {
    this.setState({
      descricao: value
    });
  };

  setTimezone(e) {
    moment.locale("pt-br");
    return moment(e).format("DD/MM/YYYY");
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  async componentDidMount() {
    this.getDocumentacao();
  }

  getDocumentacao() {
    this.setState({
      loadingDocumentos: true
    });

    api
      .get(`/documentos/${this.props.id}/${this.props.prova}/get-documentacao`)
      .then(response => {
        this.setState({
          documento: response.data,
          loadingDocumentos: false,
          idDocumento: response.data.id
        });

        this.setState({
          resultado: response.data.resultado
        });

        if (response.data.resultado === "reprovado") {
          this.catchConsideracao(response.data.id);
        }
      })
      .catch(error => {
        this.setState({
          loadingDocumentos: false
        });
      });
  }

  catchConsideracao(id) {
    api
      .get(`/geral/consideracoes/${id}`)
      .then(res => {
        this.setState({
          getConsideracao: res.data
        });
        if (Object.keys(this.state.getConsideracao).length === 0) {
          this.setState({
            getConsideracao: null
          });
        }
      })
      .catch(error => {});
  }

  reprovarDoc() {
    this.setState({
      loadingReprovado: true
    });
    const data = new FormData();

    data.append("user_id", this.props.id);
    data.append("prova_id", this.props.prova);
    data.append("id", this.state.documento.id);
    api
      .post("/documentos/reprovar-documento", data)
      .then(res => {
        this.setState(
          {
            loadingReprovado: false,
            resultado: "reprovado"
          },
          () => {
            this.getDocumentacao();
            this.setState(
              {
                message: "Documentação reprovada com sucesso.",
                theme: "success"
              },
              () => {
                this.showAlert();
              }
            );
          }
        );
      })
      .catch(error => {
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  aprovarDoc() {
    this.setState({
      loading: true
    });
    const data = new FormData();

    data.append("user_id", this.props.id);
    data.append("prova_id", this.props.prova);
    data.append("id", this.state.documento.id);
    api
      .post("/documentos/aprovar-documento", data)
      .then(res => {
        this.setState(
          {
            loading: false,
            resultado: "aprovado"
          },
          () => {
            this.getDocumentacao();
            this.setState(
              {
                message: "Documentação aprovada com sucesso.",
                theme: "success"
              },
              () => {
                this.showAlert();
              }
            );
          }
        );
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger",
            loading: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  consideracoesDoc() {
    this.setState({
      loadingAddConsideracoes: true
    });
    const data = new FormData();
    data.append("consideracao", this.state.consideracao);
    data.append("descricao", this.state.descricao);
    data.append("user_id", this.props.id);
    data.append("id", this.state.documento.id);

    api
      .post("/documentos/consideracao-reprovacao", data)
      .then(response => {
        this.setState(
          {
            open: false,
            loadingAddConsideracoes: false
          },
          () => {
            this.setState(
              {
                message: "Considerações enviada com sucesso.",
                theme: "success"
              },
              () => {
                this.showAlert();
                this.catchConsideracao(this.state.idDocumento)
              }
            );
          }
        );
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger",
            loadingAddConsideracoes: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  render() {
    const {
      documento,
      loading,
      loadingReprovado,
      loadingConsideracoes,
      loadingDocumentos,
      loadingAddConsideracoes,
      open,
      openModal,
      getConsideracao
    } = this.state;
    return (
      <>
        <Col lg="12" md="12">
          <div>
            <Alert
              className="mb-3"
              open={this.state.visible}
              theme={this.state.theme}
            >
              {this.state.message}
            </Alert>
          </div>
        </Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <div className="row">
              <div className="col-md-6">
                <h6 className="m-0">
                  Documentação{" "}
                  {this.state.resultado === "aprovado" && (
                    <Badge theme="success">Aprovado</Badge>
                  )}{" "}
                  {this.state.resultado === "reprovado" && (
                    <Badge theme="danger">Reprovado</Badge>
                  )}
                </h6>
              </div>
            </div>
          </CardHeader>
          {loadingDocumentos === false ? (
            <>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Nome do arquivo
                      </th>
                      <th scope="col" className="border-0">
                        Data de envio
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {documento.status === null && (
                        <tr>
                          <td>Envio não realizado</td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                      {documento.status != null && (
                        <>
                          <tr key={documento.id}>
                            <td>
                              <a href={documento.audio} target="_blank">
                                Áudio
                              </a>
                            </td>
                            <td>{this.setTimezone(documento.created_at)}</td>
                            <td className="d-flex justify-content-end">
                              <a
                                className="btn btn-primary btn-sm btn-squared"
                                href={documento.audio}
                                target="_blank"
                              >
                                <i className="far fa-bookmark mr-1" />
                                Download
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href={documento.transcricao} target="_blank">
                                Transcrição
                              </a>
                            </td>
                            <td>{this.setTimezone(documento.created_at)}</td>
                            <td className="d-flex justify-content-end">
                              <a
                                className="btn btn-primary btn-sm btn-squared"
                                href={documento.transcricao}
                                target="_blank"
                              >
                                <i className="far fa-bookmark mr-1" />
                                Download
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <a href={documento.autoAnalise} target="_blank">
                                Auto análise
                              </a>
                            </td>
                            <td>{this.setTimezone(documento.created_at)}</td>
                            <td className="d-flex justify-content-end">
                              <a
                                className="btn btn-primary btn-sm btn-squared"
                                href={documento.autoAnalise}
                                target="_blank"
                              >
                                <i className="far fa-bookmark mr-1" />
                                Download
                              </a>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  </tbody>
                </table>
              </CardBody>
              {this.state.resultado === null && (
                <CardFooter
                  style={{ borderTop: "1px #eee solid" }}
                  align="right"
                >
                  <Button
                    onClick={() => this.reprovarDoc()}
                    size="sm"
                    theme="danger"
                    style={{ marginRight: "15px" }}
                    disabled={loadingReprovado === true}
                  >
                    {loadingReprovado === true && (
                      <>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ paddingLeft: "8px" }}
                          >
                            <CircleSpinner
                              size={20}
                              color="#fff"
                              loading={loadingReprovado}
                            ></CircleSpinner>
                          </div>
                          <div className="col">
                            <span
                              style={{
                                paddingTop: "4px",
                                verticalAlign: "middle"
                              }}
                            >
                              Reprovando documentação...
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {loadingReprovado === false && (
                      <>
                        <i className="far fa-bookmark mr-1" />
                        Reprovar documentação
                      </>
                    )}
                  </Button>
                  <Button
                    onClick={() => this.aprovarDoc()}
                    size="sm"
                    theme="success"
                    disabled={loading === true}
                  >
                    {loading === true && (
                      <>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ paddingLeft: "8px" }}
                          >
                            <CircleSpinner
                              size={20}
                              color="#fff"
                              loading={loading}
                            ></CircleSpinner>
                          </div>
                          <div className="col">
                            <span
                              style={{
                                paddingTop: "4px",
                                verticalAlign: "middle"
                              }}
                            >
                              Aprovando documentação...
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {loading === false && (
                      <>
                        <i className="far fa-bookmark mr-1" />
                        Aprovar documentação
                      </>
                    )}
                  </Button>
                </CardFooter>
              )}

              {this.state.resultado === "reprovado" && (
                <>
                  {this.state.getConsideracao === null && (
                    <CardFooter
                      style={{ borderTop: "1px #eee solid" }}
                      align="right"
                    >
                      <Button onClick={this.toggle} size="sm" theme="danger">
                        <i className="far fa-bookmark mr-1" />
                        Adicionar considerações
                      </Button>
                    </CardFooter>
                  )}
                  {this.state.getConsideracao != null && (
                    <>
                      <CardFooter
                        style={{ borderTop: "1px #eee solid" }}
                        align="right"
                      >
                        <Button
                          onClick={this.toggleModal}
                          size="sm"
                          theme="info"
                        >
                          <i className="far fa-bookmark mr-1" />
                          Visualizar considerações
                        </Button>
                      </CardFooter>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className={`${styles.card}`}></div>
          )}
        </Card>
        {this.state.resultado === "reprovado" && (
          <DocumentacaoEnvioReprovado
            id={this.props.match.params.id}
            prova={this.props.match.params.prova}
          />
        )}
        <Modal
          open={open}
          toggle={this.toggle}
          style={{
            overflowY: "scroll !important",
            maxHeight: "120px !important"
          }}
        >
          <ModalHeader>Enviar e-mail reprovado com considerações</ModalHeader>
          <ModalBody>
            <FormGroup>
              <label htmlFor="username">Consideração</label>
              <FormInput id="username" onChange={this.onChangeConsideracao} />
            </FormGroup>
            <FormGroup>
              <label style={{ fontWeight: "600" }}>Descrição</label>
              <ReactQuill
                className="add-new-post__editor mb-1"
                onChange={this.handleQuillChange}
                style={{ maxHeight: "120px", overflowY: "scroll" }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter align="right">
            <Button
              pill
              theme="success"
              onClick={() => this.consideracoesDoc()}
              disabled={loadingAddConsideracoes === true}
            >
              {loadingAddConsideracoes === true && (
                <>
                  <div className="row">
                    <div className="col-auto" style={{ paddingLeft: "8px" }}>
                      <CircleSpinner
                        size={20}
                        color="#fff"
                        loading={loadingAddConsideracoes}
                      ></CircleSpinner>
                    </div>
                    <div className="col">
                      <span
                        style={{
                          paddingTop: "4px",
                          verticalAlign: "middle"
                        }}
                      >
                        Enviando considerações...
                      </span>
                    </div>
                  </div>
                </>
              )}
              {loadingAddConsideracoes === false && (
                <>
                  <i className="far fa-bookmark mr-1" />
                  Enviar e-mail
                </>
              )}
            </Button>
          </ModalFooter>
        </Modal>

        {this.state.getConsideracao != null && (
          <>
            <Modal open={openModal} toggle={this.toggleModal}>
              <ModalHeader>Considerações</ModalHeader>
              <ModalBody>
                <h5>Consideração</h5>
                <p>{getConsideracao.consideracao}</p>
                <h5 style={{ marginTop: "15px" }}>Descrição</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: getConsideracao.descricao
                  }}
                  style={{ textAlign: "justify" }}
                ></p>
              </ModalBody>
            </Modal>
          </>
        )}
      </>
    );
  }
}

export default withRouter(DocumentacaoPrimeiroEnvio);
