import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../../services/api";
import { timing } from "react-ga";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import "../../../../../assets/quill.css";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../../Categorias/styles.module.css";
import ReactQuill from "react-quill";

class DocumentacaoPrimeiroEnvio extends React.Component {
  state = {
    documentosReprovados: [],
    resultadoReprovado: "",
    loadingDocumentacaoDois: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loadingAddConsideracoes: false,
    consideracao: "",
    descricao: "",
    open: false,
    loadingReprovado: false,
    loading: false,
    idReprovado: ""
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setTimezone = this.setTimezone.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.onChangeConsideracao = this.onChangeConsideracao.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  onChangeConsideracao(e) {
    e.preventDefault();
    this.setState({
      consideracao: e.target.value
    });
  }

  handleQuillChange = value => {
    this.setState({
      descricao: value
    });
  };

  setTimezone(e) {
    moment.locale("pt-br");
    return moment(e).format("DD/MM/YYYY");
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  async componentDidMount() {
    this.getDocumentacao();
  }

  getDocumentacao() {
    this.setState({
      loadingDocumentos: true
    });

    api
      .get(
        `/documentos/${this.props.id}/${this.props.prova}/reprovado/get-documentacao`
      )
      .then(res => {
        this.setState({
          documentosReprovados: res.data,
          resultadoReprovado: res.data["0"].resultado,
          loadingDocumentacaoDois: false,
          idReprovado: res.data["0"].id
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loadingDocumentacaoDois: false
        });
      });
  }

  reprovarDocSegundoEnvio() {
    this.setState({
      loadingReprovado: true
    });
    const data = new FormData();

    data.append("user_id", this.props.id);
    data.append("prova_id", this.props.prova);
    data.append("id", this.state.idReprovado);
    api
      .post("/documentos/reprovados/reprovar-documento", data)
      .then(res => {
        this.setState(
          {
            loadingReprovado: false
          },
          () => {
            this.getDocumentacao();
          }
        );
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger",
            loadingReprovado: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  aprovarDocSegundoEnvio() {
    this.setState({
      loading: true
    });
    const data = new FormData();

    data.append("user_id", this.props.id);
    data.append("prova_id", this.props.prova);
    data.append("id", this.state.idReprovado);
    api
      .post("/documentos/reprovados/aprovar-documento", data)
      .then(res => {
        this.setState(
          {
            loading: false
          },
          () => {
            this.getDocumentacao();
          }
        );
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger",
            loading: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  render() {
    const {
      open,
      documentosReprovados,
      loadingDocumentacaoDois,
      loading,
      loadingAddConsideracoes,
      loadingReprovado
    } = this.state;
    return (
      <>
      <Col lg="12" md="12">
          <div>
            <Alert
              className="mb-3"
              open={this.state.visible}
              theme={this.state.theme}
            >
              {this.state.message}
            </Alert>
          </div>
        </Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <div className="row">
              <div className="col-md-6">
                <h6 className="m-0">
                  Documentação (adicionais à primeira análise){" "}
                  {this.state.resultadoReprovado === "aprovado" && (
                    <Badge theme="success">Aprovado</Badge>
                  )}{" "}
                  {this.state.resultadoReprovado === "reprovado" && (
                    <Badge theme="danger">Reprovado</Badge>
                  )}
                </h6>
              </div>
            </div>
          </CardHeader>
          {loadingDocumentacaoDois === false ? (
            <>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Nome do arquivo
                      </th>
                      <th scope="col" className="border-0">
                        Data de envio
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentosReprovados.map(documento => (
                      <>
                        {documento.status != null && (
                          <>
                            <tr key={documento.id}>
                              <td>
                                <a href={documento.audio} target="_blank">
                                  Áudio
                                </a>
                              </td>
                              <td>{this.setTimezone(documento.created_at)}</td>
                              <td className="d-flex justify-content-end">
                                <a
                                  className="btn btn-primary btn-sm btn-squared"
                                  href={documento.audio}
                                  target="_blank"
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Download
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href={documento.transcricao} target="_blank">
                                  Transcrição
                                </a>
                              </td>
                              <td>{this.setTimezone(documento.created_at)}</td>
                              <td className="d-flex justify-content-end">
                                <a
                                  className="btn btn-primary btn-sm btn-squared"
                                  href={documento.transcricao}
                                  target="_blank"
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Download
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href={documento.autoAnalise} target="_blank">
                                  Auto análise
                                </a>
                              </td>
                              <td>{this.setTimezone(documento.created_at)}</td>
                              <td className="d-flex justify-content-end">
                                <a
                                  className="btn btn-primary btn-sm btn-squared"
                                  href={documento.autoAnalise}
                                  target="_blank"
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Download
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a href={documento.comprovantePagamento} target="_blank">
                                  Comprovante de pagamento
                                </a>
                              </td>
                              <td>{this.setTimezone(documento.created_at)}</td>
                              <td className="d-flex justify-content-end">
                                <a
                                  className="btn btn-primary btn-sm btn-squared"
                                  href={documento.comprovantePagamento}
                                  target="_blank"
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Download
                                </a>
                              </td>
                            </tr>
                          </>
                        )}
                        {documento.status === null && (
                          <tr>
                            <td>Envio não realizado</td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </CardBody>
              {this.state.resultadoReprovado === null && (
                <CardFooter
                  style={{ borderTop: "1px #eee solid" }}
                  align="right"
                >
                  <Button
                    onClick={() => this.reprovarDocSegundoEnvio()}
                    size="sm"
                    theme="danger"
                    style={{ marginRight: "15px" }}
                    disabled={loadingReprovado === true}
                  >
                    {loadingReprovado === true && (
                      <>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ paddingLeft: "8px" }}
                          >
                            <CircleSpinner
                              size={20}
                              color="#fff"
                              loading={loadingReprovado}
                            ></CircleSpinner>
                          </div>
                          <div className="col">
                            <span
                              style={{
                                paddingTop: "4px",
                                verticalAlign: "middle"
                              }}
                            >
                              Reprovando documentação...
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {loadingReprovado === false && (
                      <>
                        <i className="far fa-bookmark mr-1" />
                        Reprovar documentação
                      </>
                    )}
                  </Button>
                  <Button
                    onClick={() => this.aprovarDocSegundoEnvio()}
                    size="sm"
                    theme="success"
                    disabled={loading === true}
                  >
                    {loading === true && (
                      <>
                        <div className="row">
                          <div
                            className="col-auto"
                            style={{ paddingLeft: "8px" }}
                          >
                            <CircleSpinner
                              size={20}
                              color="#fff"
                              loading={loading}
                            ></CircleSpinner>
                          </div>
                          <div className="col">
                            <span
                              style={{
                                paddingTop: "4px",
                                verticalAlign: "middle"
                              }}
                            >
                              Aprovando documentação...
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {loading === false && (
                      <>
                        <i className="far fa-bookmark mr-1" />
                        Aprovar documentação
                      </>
                    )}
                  </Button>
                </CardFooter>
              )}
            </>
          ) : (
            <div className={`${styles.card}`}></div>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(DocumentacaoPrimeiroEnvio);
