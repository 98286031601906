import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  FormSelect,
  CardHeader,
  CardBody,
  CardFooter,
  Alert
} from "shards-react";
import ReactQuill from "react-quill";
import Editor from "../../../../components/add-new-post/Editor";
import { Link, useHistory, withRouter } from "react-router-dom";
import PageTitle from "../../../../components/common/PageTitle";
import api from "../../../../services/api";
import "react-quill/dist/quill.snow.css";
import "../../../../assets/quill.css";
import Dropzone from "react-dropzone";
import { uniqueId } from "lodash";
import filesize from "filesize";
import Upload from "../../../../components/upload-image/Upload";
import FileList from "../../../../components/upload-image/FileList";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";
const data = new FormData();
class CadastrarProva extends React.Component {
  state = {
    titulo: "",
    text: "",
    buttonState: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false
  };

  constructor(props) {
    super(props);
    this.onChangeTitulo = this.onChangeTitulo.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  onChangeTitulo(e) {
    e.preventDefault();
    this.setState({
      titulo: e.target.value
    });
  }

  handleQuillChange = value => {
    this.setState({
      texto: value
    });
  };

  processUpload() {
    this.setState({
      loading: true
    })
    const data = new FormData();
    data.append("titulo", this.state.titulo);
    data.append("texto", this.state.texto);
    data.append("duracao_maxima", "60");

    api
      .post("provas/file-upload", data)
      .then(response => {
        this.setState({
          loading: false
        })
        this.props.history.push("/provas", response.data.id);
      })
      .catch(error => {
        this.setState({
          message: "Ops! Algo saiu errado, tente novamente.",
          theme: "danger",
          loading: false
        }, () => {
          this.showAlert()
        });
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { loading } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Cadastrar prova"
            subtitle="OVERVIEW"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-3">
              <CardBody>
                <Form className="add-new-post">
                  <FormGroup>
                    <label style={{ fontWeight: "600" }}>Título</label>
                    <FormInput
                      size="lg"
                      className="mb-3"
                      placeholder="Título da prova"
                      onChange={this.onChangeTitulo}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label style={{ fontWeight: "600" }}>Descrição</label>
                    <ReactQuill
                      className="add-new-post__editor mb-1"
                      onChange={this.handleQuillChange}
                    />
                  </FormGroup>

                  <FormGroup align="right">
                    <Button
                      onClick={() => this.processUpload()}
                      disabled={loading === true}
                    >
                      {loading === true && (
                        <>
                          <div className="row">
                            <div
                              className="col-auto"
                              style={{ paddingLeft: "8px" }}
                            >
                              <CircleSpinner
                                size={20}
                                color="#fff"
                                loading={loading}
                              ></CircleSpinner>
                            </div>
                            <div className="col">
                              <span
                                style={{
                                  paddingTop: "4px",
                                  verticalAlign: "middle"
                                }}
                              >
                                Cadastrando prova...
                              </span>
                            </div>
                          </div>
                        </>
                      )}{" "}
                      {loading === false && <>Cadastrar prova</>}
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(CadastrarProva);
