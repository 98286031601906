import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../services/api";
import ReactPaginate from "react-paginate";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../Categorias/styles.module.css";
import GraficoAluno from "./GraficoAluno";
import DocumentacaoPrimeiroEnvio from "./DocumentacaoPrimeiroEnvio";
import QuestaosList from "./QuestaosList";

class Aluno extends React.Component {
  state = {
    id: "",
    prova: "",
    suspenso: "",
    name: "",
    email: "",
    pontuacao: "",
    status: "",
    tempo: ""
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await api
      .get(`/users/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          id: res.data.id,
          prova: this.props.match.params.prova,
          name: res.data.name,
          email: res.data.email
        });
      })
      .catch(error => {});

    api
      .get(
        `/provas/get-tempo-pontuacao/${this.props.match.params.id}/${this.props.match.params.prova}`
      )
      .then(res => { 
        this.setState({
          pontuacao: res.data["0"].pontuacao,
          status: res.data["0"].status,
          tempo: res.data["0"].tempo
        });
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title={this.state.name}
            subtitle="Detalhe do cadastro"
            className="text-sm-left"
          />
        </Row>

        <div className="row">
          {this.state.status != null && (
            <>
              <div className="col-md-4 col-12">
                {this.state.status != null && (
                  <>
                    {this.state.pontuacao && (
                      <GraficoAluno
                        resultado={this.state.status}
                        pontuacao={this.state.pontuacao}
                        tempo={this.state.tempo}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-md-8 col-12">
                <QuestaosList
                  id={this.props.match.params.id}
                  prova={this.props.match.params.prova}
                  status={this.state.status}
                />
              </div>
            </>
          )}
          {this.state.status === null && (
            <div className="col-md-12 col-12">
              <QuestaosList
                id={this.props.match.params.id}
                prova={this.props.match.params.prova}
                status={this.state.status}
              />
            </div>
          )}
        </div>

        <div className="row" style={{ marginTop: "35px" }}>
          <div className="col-md-12 col-12">
            {this.state.status === "1" && (
              <DocumentacaoPrimeiroEnvio
                id={this.props.match.params.id}
                prova={this.props.match.params.prova}
              />
            )}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Aluno);
