import React from "react";
import {
  Container
} from "shards-react";

class Questoes extends React.Component {

  render() {
    return (
        <></>
    );
  }
}

export default Questoes;
