import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox,
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import { Link } from "react-router-dom";
import { SortablePane, Pane } from "react-sortable-pane";

class Questao extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.state = {
      orange: false,
      lemon: false,
      kiwi: false
    };
  }

  handleChange(e, fruit) {
    const newState = {};
    newState[fruit] = !this.state[fruit];
    this.setState({ ...this.state, ...newState });
  }

  render() {
    const { open } = this.state;

    const panes = [0, 1, 2, 3, 4].map(key => (
      <Pane key={key} defaultSize={{ width: "100%" }}>
        <InputGroup className="mb-2">
          <InputGroupAddon type="prepend">
            <InputGroupText
              style={{
                paddingTop: "12px",
                paddingLeft: "20px",
                alignItems: "center"
              }}
            >
              <FormCheckbox
                checked
                onChange={e => this.handleChange(e, "lemon")}
              ></FormCheckbox>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            value="a) São Paulo Futebol Clube"
            readonly
            className="form-control"
          />
          <InputGroupAddon type="append">
            <Button theme="danger">
              <i className="material-icons" style={{ fontSize: "21px" }}>
                close
              </i>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Pane>
    ));

    function addPanel() {
      panes.push(
        <Pane defaultSize={{ width: "100%" }}>
        <InputGroup className="mb-2">
          <InputGroupAddon type="prepend">
            <InputGroupText
              style={{
                paddingTop: "12px",
                paddingLeft: "20px",
                alignItems: "center"
              }}
            >
              <FormCheckbox
                checked
                onChange={e => this.handleChange(e, "lemon")}
              ></FormCheckbox>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            value="a) São Paulo Futebol Clube"
            readonly
            className="form-control"
          />
          <InputGroupAddon type="append">
            <Button theme="danger">
              <i className="material-icons" style={{ fontSize: "21px" }}>
                close
              </i>
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </Pane>
      );
    }

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title="Qual o melhor time do mundo?"
            subtitle="Detalhe da questão"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Questão</h6>
              </CardHeader>
              <CardBody className="pb-3">
                <Form>
                  <div className="row">
                    <div className="col-md-10">
                      <FormGroup>
                        <label htmlFor="username" style={{ fontWeight: "600" }}>
                          Título da questão
                        </label>
                        <FormInput
                          className="form-control"
                          id="username"
                          type="text"
                          value="Qual o melhor time do mundo?"
                          readonly
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-2">
                      <FormGroup>
                        <label htmlFor="username" style={{ fontWeight: "600" }}>
                          Pontos
                        </label>
                        <FormInput
                          className="form-control"
                          id="username"
                          type="text"
                          value="2"
                          readonly
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <FormGroup>
                    <label htmlfor="select" style={{ fontWeight: "600" }}>
                      Categorias
                    </label>
                    <FormSelect multiple>
                      <option value="first">This is the first option</option>
                      <option value="second">This is the second option.</option>
                      <option value="third" disabled>
                        This option is disabled.
                      </option>
                    </FormSelect>
                  </FormGroup>

                  <div style={{ height: "450px" }}>
                    <SortablePane
                      direction="vertical"
                      margin={5}
                      defaultOrder={["0", "1", "2", "3", "4"]}
                    >
                      {panes}
                    </SortablePane>
                  </div>
                </Form>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }}>
                <div className="row">
                  <div className="col-md-6">
                    <Button
                      size="sm"
                      theme="success"
                      onClick={() => addPanel()}
                    >
                      <i className="far fa-bookmark mr-1" />
                      Adicionar alternativa
                    </Button>
                  </div>
                  <div className="col-md-6" align="right">
                    <Button size="sm" theme="white">
                      <i className="far fa-bookmark mr-1" />
                      Editar questão
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Questao;
