import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import { timing } from "react-ga";
import moment from "moment";
import { MdHighlightOff, MdCheckCircle } from "react-icons/md";
import ReactPaginate from "react-paginate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../../assets/quill.css";
import QuestaosListsReprovado from "./QuestaosListsReprovado";
import GraficoAlunoReprovado from "./GraficoAlunoReprovado";
import DocumentacaoPrimeiroEnvioReprovado from "./DocumentacaoPrimeiroEnvioReprovado";
import GraficoAluno from "../../../Provas/Aluno/GraficoAluno";

class AlunoReprovado extends React.Component {
  state = {
    documentos: [],
    id: "",
    prova: "",
    suspenso: "",
    name: "",
    email: "",
    pontuacao: "",
    status: "",
    tempo: "",
    alternativas: [],
    resultado: "",
    open: false,
    descricao: "",
    consideracao: "",
    documentosReprovados: [],
    resultadoReprovado: ""
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    api
      .get(
        `/provas/get-tempo-pontuacao-reprovado/${this.props.match.params.id}/${this.props.match.params.prova}`
      )
      .then(res => {
        this.setState(
          {
            pontuacao: res.data["0"].pontuacao,
            status: res.data["0"].status,
            tempo: res.data["0"].tempo
          },
          () => {
            api
              .get(`/users/${this.props.match.params.id}`)
              .then(res => {
                this.setState({
                  id: res.data.id,
                  prova: this.props.match.params.prova,
                  name: res.data.name,
                  suspenso: res.data.suspenso,
                  email: res.data.email
                });
              })
              .catch(error => {});
          }
        );
      })
      .catch(error => {});
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  render() {
    const {
      documentos,
      alternativas,
      alternativa_respondida_id,
      user_has_questions_id,
      textoAlternativa,
      correta,
      nota,
      open,
      documentosReprovados,
      status,
      pontuacao
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title={this.state.name}
            subtitle="Detalhe do cadastro"
            className="text-sm-left"
          />
        </Row>
        <div className="row">
          {this.state.status != null && (
            <>
              <div className="col-md-4 col-12">
                {this.state.status != null && (
                  <>
                    {this.state.pontuacao && (
                      <GraficoAluno
                        resultado={this.state.status}
                        pontuacao={this.state.pontuacao}
                        tempo={this.state.tempo}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-md-8 col-12">
                <QuestaosListsReprovado
                  id={this.props.match.params.id}
                  prova={this.props.match.params.prova}
                  status={this.state.status}
                />
              </div>
            </>
          )}
          {this.state.status === null && (
            <div className="col-md-12 col-12">
              <QuestaosListsReprovado
                id={this.props.match.params.id}
                prova={this.props.match.params.prova}
                status={this.state.status}
              />
            </div>
          )}
        </div>
        <div className="row" style={{ marginTop: "15px" }}>
          <div className="col-md-12">
            <DocumentacaoPrimeiroEnvioReprovado
              id={this.props.match.params.id}
              prova={this.props.match.params.prova}
              status={this.state.status}
            />
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(AlunoReprovado);
