import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import Editor from "../../components/add-new-post/Editor";
import { Link } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import api from "../../services/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "../Turmas/styles.css";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../Categorias/styles.module.css";

class ProvasReprovados extends React.Component {
  state = {
    provas: [],
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
    filteredData: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    alunos: [],
    selectedOption: null,
    idProvaSelecionada: "",
    open: false,
    alunos: [],
    loading: false,
    loadingConvidarAluno: false
  };

  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    this.setState({
      open: !this.state.open,
      idProvaSelecionada: id
    });
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.provas.filter(el =>
        el.titulo.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  async receivedData() {
    this.setState({
      loading: true
    });
    await api
      .get("provas/provas-reprovados")
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          provas: slice,
          loading: false
        });

        this.setState({
          filteredData: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        console.log(error.response);
        this.setState(
          {
            //message: "Houve um erro na requisição, tente novamente.",
            //theme: "danger",
            loading: false
          } /*,
          () => {
            this.showAlert();
          }*/
        );
      });
  }

  async componentDidMount() {
    this.receivedData();

    if (this.props.location.state === true) {
      this.setState({
        message: "Prova gerada com sucesso.",
        theme: "success"
      });
      this.showAlert();
    } else if (this.props.location.state === true) {
      this.setState({
        message: "Prova alterada com sucesso.",
        theme: "success"
      });
      this.showAlert();
    }

    api
      .get("users/list-students-select")
      .then(res => {
        this.setState({
          alunos: res.data
        });
      })
      .catch(error => {
        console.log(error.response);
        /*this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );*/
      });
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  async handleSubmit() {
    this.setState({
      loadingConvidarAluno: true
    });
    const data = new FormData();
    data.append("aluno", JSON.stringify(this.state.selectedOption));
    data.append("prova_id", this.state.idProvaSelecionada);

    await api
      .post("/provas-reprovados/user-prova", data)
      .then(res => {
        this.setState(
          {
            open: false,
            loadingConvidarAluno: false
          },
          () => {
            this.setState(
              {
                message:
                  "Usuário convidado para realização da prova com sucesso.",
                theme: "success"
              },
              () => {
                this.showAlert();
              }
            );
          }
        );
      })
      .catch(
        error => {
          console.log(error);
          this.setState({
            loadingConvidarAluno: false,
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          });
        },
        () => {
          this.showAlert();
        }
      );
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    console.log("Prova: " + this.state.idProvaSelecionada);
  };

  render() {
    const { provas, open, loading, loadingConvidarAluno } = this.state;
    const { filteredData } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Provas reprovados"
            subtitle="Provas reprovados cadastradas no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6 className="m-0">Provas reprovados</h6>
                  </div>
                  <div className="col-md-8 col-8 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <InputGroup size="md" seamless>
                          <InputGroupAddon type="prepend">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            placeholder="Pesquisar pelo título da prova"
                            style={{ fontSize: "14px" }}
                            onChange={this.handleSearchChange}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loading === false ? (
                <>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Título
                          </th>
                          <th scope="col" className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((prova, index) => (
                          <tr key={prova.id}>
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/provas-reprovado/${prova.id}`,
                                  prova: {
                                    id: prova.id
                                  }
                                }}
                                style={{ fontWeight: "600" }}
                              >
                                {prova.titulo}
                              </Link>
                            </td>
                            <td className="d-flex justify-content-end">
                              <Button
                                onClick={() => this.toggle(prova.id)}
                                style={{ marginRight: "15px" }}
                              >
                                Convidar aluno
                              </Button>
                              <Link
                                to={{
                                  pathname: `/provas-reprovados-edit/${prova.id}`,
                                  prova: {
                                    titulo: prova.titulo,
                                    id: prova.id,
                                    texto: prova.texto,
                                    prova_id: prova.prova_id
                                  }
                                }}
                                className="btn btn-white btn-sm"
                                type="button"
                              >
                                <i className="far fa-bookmark mr-1" /> Editar
                                prova
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter style={{ borderTop: "1px #eee solid" }}>
                    <div
                      align="center"
                      className="d-flex justify-content-center"
                    >
                      <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </div>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>Convidar usuário para realizar prova</ModalHeader>
          <ModalBody>
            <label style={{ fontWeight: "600" }}>Selecionar usuário</label>
            <Select onChange={this.handleChange} options={this.state.alunos} />
          </ModalBody>
          <ModalFooter align="right">
            <Button
              onClick={() => this.handleSubmit()}
              disabled={loadingConvidarAluno === true}
            >
              {loadingConvidarAluno === true && (
                <>
                  <div className="row">
                    <div className="col-auto" style={{ paddingLeft: "8px" }}>
                      <CircleSpinner
                        size={20}
                        color="#fff"
                        loading={loadingConvidarAluno}
                      ></CircleSpinner>
                    </div>
                    <div className="col">
                      <span
                        style={{ paddingTop: "4px", verticalAlign: "middle" }}
                      >
                        Convidando aluno...
                      </span>
                    </div>
                  </div>
                </>
              )}{" "}
              {loadingConvidarAluno === false && <>Convidar aluno</>}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default ProvasReprovados;
