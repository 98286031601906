import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge
} from "shards-react";

import Chart from "../../../../../utils/chart";

class GraficoAlunoReprovado extends React.Component {
  state = {
    title: "Desempenho"
  };
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    console.log("Entrei");

    const chartConfig = {
      type: "pie",
      data: {
        datasets: [
          {
            hoverBorderColor: "#ffffff",
            data: [this.props.pontuacao, 100 - this.props.pontuacao],
            backgroundColor: ["rgba(0,123,255,0.9)", "rgba(0,123,255,0.3)"]
          }
        ],
        labels: ["Correta", "Incorreta"]
      },
      options: {
        ...{
          legend: {
            position: "bottom",
            labels: {
              padding: 25,
              boxWidth: 20
            }
          },
          cutoutPercentage: 0,
          tooltips: {
            custom: false,
            mode: "index",
            position: "nearest"
          }
        }
      }
    };

    new Chart(this.canvasRef.current, chartConfig);
  }

  render() {
    const { title } = this.state;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">
            {title}{" "}
            {this.props.resultado === "1" && (
              <Badge theme="success" style={{ marginLeft: "5px" }}>
                Aprovado
              </Badge>
            )}
            {this.props.resultado === "0" && (
              <Badge theme="danger" style={{ marginLeft: "5px" }}>
                Reprovado
              </Badge>
            )}
          </h6>
        </CardHeader>
        <CardBody
          className="d-flex py-0"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
        <CardFooter className="border-top">
          <Row>
            <Col className="view-report">
              Pontuação:{" "}
              <span style={{ fontWeight: "600" }}>{this.props.pontuacao}%</span>
            </Col>
            <Col className="text-right view-report">
              Prova realizada em:{" "}
              <span style={{ fontWeight: "600" }}>{this.props.tempo}</span>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

export default GraficoAlunoReprovado;
