import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormInput,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import ReactPaginate from "react-paginate";
import "../../../Turmas/styles.css";
import styles from "../../../Categorias/styles.module.css";

class AlunoList extends React.Component {
  state = {
    alunos: [],
    offset: 0,
    data: [],
    perPage: 9,
    currentPageAlunos: 0,
    offsetAlunos: 0,
    filteredDataAlunos: [],
    loadingAlunos: false
  };

  constructor(props) {
    super(props);
    this.handlePageClickAlunos = this.handlePageClickAlunos.bind(this);
    this.handleSearchChangeAlunos = this.handleSearchChangeAlunos.bind(this);
  }

  handleSearchChangeAlunos = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredDataAlunos = prevState.alunos.filter(el =>
        el.name.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredDataAlunos };
    });
  };

  async receivedDataAlunos() {
    this.setState({
      loadingAlunos: true
    });
    await api
      .get(`users/list-students-by-prova/${this.props.id}`)
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          alunos: slice,
          loadingAlunos: false,
          filteredDataAlunos: slice,
          pageCountAlunos: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        console.log(error.response);
        this.setState({
          loadingAlunos: false
        });
      });
  }

  async componentDidMount() {
    this.receivedDataAlunos();
  }

  handlePageClickAlunos = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPageAlunos: selectedPage,
        offsetAlunos: offset
      },
      () => {
        this.receivedDataAlunos();
      }
    );
  };

  render() {
    const { loadingAlunos, alunos, filteredDataAlunos } = this.state;
    return (
      <Card small className="mb-4 h-100">
        <CardHeader className="border-bottom">
          <div className="row">
            <div className="col-md-4 col-4">
              <h6 className="m-0">Alunos</h6>
            </div> 
            <div className="col-md-8 col-8 d-flex justify-content-end">
              <div className="row">
                <div className="col-md-12 col-12">
                  <InputGroup size="md" seamless>
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      placeholder="Pesquisar pelo nome do aluno"
                      style={{ fontSize: "14px" }}
                      onChange={this.handleSearchChangeAlunos}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        {loadingAlunos === false ? (
          <>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Nome
                    </th>
                    <th scope="col" className="border-0">
                      E-mail
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDataAlunos.map(aluno => (
                    <tr key={aluno.id}>
                      <td>{aluno.id}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `/aluno/${aluno.id}/${this.props.id}`
                          }}
                          style={{ fontWeight: "600" }}
                        >
                          {aluno.name}
                        </Link>
                      </td>
                      <td>
                        <a href={`mailto:${aluno.email}`}>{aluno.email}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
            <CardFooter style={{ borderTop: "1px #eee solid" }}>
              <div align="center" className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCountAlunos}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClickAlunos}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </div>
            </CardFooter>
          </>
        ) : (
          <div className={`${styles.card}`}></div>
        )}
      </Card>
    );
  }
}

export default withRouter(AlunoList);
