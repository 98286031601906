import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  CardFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import api from "../../../../services/api";
import "react-quill/dist/quill.snow.css";
import "../../../../assets/quill.css";
import Dropzone from "react-dropzone";
import { uniqueId } from "lodash";
import filesize from "filesize";
import ReactQuill from "react-quill";
import Upload from "../../../../components/upload-image/Upload";
import FileList from "../../../../components/upload-image/FileList";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";
class EditProva extends React.Component {
  state = {
    id: "",
    texto: "",
    titulo: "",
    dataCadastro: "",
    duracaoMaxima: "",
    tituloBotao: "",
    questoes: [],
    alunos: [],
    nome: "",
    type: "",
    email: "",
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    loadingEdit: false
  };

  constructor(props) {
    super(props);
    this.onChangeTitulo = this.onChangeTitulo.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await api
      .get(`/provas/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          id: res.data.id,
          titulo: res.data.titulo,
          texto: res.data.texto,
          dataCadastro: res.data.dataCadastro,
          duracaoMaxima: res.data.duracaoMaxima,
          tituloBotao: res.data.tituloBotao,
          loading: false
        });

        this.receivedData();
        this.receivedDataAlunos();
      })
      .catch(error => {
        this.setState(
          {
            loading: false,
          }
        );
      });
  }

  onChangeTitulo(e) {
    e.preventDefault();
    this.setState({
      titulo: e.target.value
    });
  }

  handleQuillChange = value => {
    this.setState({
      texto: value
    });
  };

  processUpload() {
    this.setState({
      loadingEdit: true
    });
    const data = new FormData();

    data.append("titulo", this.state.titulo);
    data.append("texto", this.state.texto);
    data.append("duracao_maxima", "60");

    api
      .post(`provas/${this.state.id}/update`, data)
      .then(response => {
        this.setState({
          loadingEdit: false
        });
        this.props.history.push("/provas", false);
      })
      .catch(error => {
        this.setState(
          {
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger",
            loadingEdit: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  showAlert() {
    console.log("entrei");
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { loading, loadingEdit } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Editar prova"
            subtitle="Prova cadastrada no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-3">
              {loading === false ? (
                <>
                  <CardBody>
                    <Form className="add-new-post">
                      <FormGroup>
                        <label style={{ fontWeight: "600" }}>
                          Título da prova
                        </label>
                        <input
                          type="text"
                          value={this.state.titulo}
                          onChange={this.onChangeTitulo}
                          className="form-control"
                        />
                      </FormGroup>

                      <FormGroup>
                        <label style={{ fontWeight: "600" }}>Descrição</label>
                        <ReactQuill
                          className="add-new-post__editor mb-1"
                          onChange={this.handleQuillChange}
                          value={this.state.texto}
                        />
                      </FormGroup>

                      <FormGroup align="right">
                        <Button
                          onClick={() => this.processUpload()}
                          disabled={loadingEdit === true}
                        >
                          {loadingEdit === true && (
                            <>
                              <div className="row">
                                <div
                                  className="col-auto"
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <CircleSpinner
                                    size={20}
                                    color="#fff"
                                    loading={loadingEdit}
                                  ></CircleSpinner>
                                </div>
                                <div className="col">
                                  <span
                                    style={{
                                      paddingTop: "4px",
                                      verticalAlign: "middle"
                                    }}
                                  >
                                    Alterando dados...
                                  </span>
                                </div>
                              </div>
                            </>
                          )}{" "}
                          {loadingEdit === false && <>Alterar prova</>}
                        </Button>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EditProva;
