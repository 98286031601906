import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import Select from "react-select";
import AlternativaQuestao from "./AlternativaQuestao";
import AdicionarAlternativa from "./AdicionarAlternativa";

const array = [];

class EditQuestao extends React.Component {
  state = {
    categoria_id: null,
    alternativas: [],
    categorias: [],
    titulo: null,
    id: null,
    inputList: [{ correta: "0", questao: "" }],
    inputedList: [{ correta: "0", questao: "" }],
    multiValue: [],
    atualizou: false,
    categoria_checked: [],
    novaAlternativa: false,
    novasAlternativas: [],
    visible: false,
    theme: "",
    message: "",
    countdown: 0,
    timeUntilDismissed: 5
  };
  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
      titulo: this.props.location.questao.titulo
    });

    api
      .get("categorias/list-all")
      .then(response => {
        this.setState({
          categorias: response.data
        });
        api
          .get(`questoes/${this.props.match.params.id}/list-categories-all`)
          .then(response => {
            this.setState({
              categoria_id: response.data
            });

            api
              .get(
                `alternativas/${this.props.match.params.id}/list-alternativas`
              )
              .then(response => {
                this.setState({
                  alternativas: response.data,
                  inputedList: response.data
                });
              })
              .catch(error => {
                console.log(error.response);
                /*this.setState(
                  {
                    message: "Houve um erro na requisição, tente novamente.",
                    theme: "danger"
                  },
                  () => {
                    this.showAlert();
                  }
                );*/
              });
          })
          .catch(error => {
            console.log(error.response);
            /*this.setState(
              {
                message: "Houve um erro na requisição, tente novamente.",
                theme: "danger"
              },
              () => {
                this.showAlert();
              }
            );*/
          });
      })
      .catch(error => {
        console.log(error.response);
        /*this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );*/
      });

    this.onChangeTitulo = this.onChangeTitulo.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.mudou = this.mudou.bind(this);
    this.removeFilho = this.removeFilho.bind(this);
    this.salveiFilho = this.salveiFilho.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  onChangeTitulo(e) {
    this.setState({
      titulo: e.target.value
    });
  }

  handleMultiChange(option) {
    this.setState({
      categoria_id: option
    });

    array.push(option);
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();

    data.append("titulo", this.state.titulo);
    data.append("id", this.props.match.params.id);
    data.append(
      "alternativas_alterar",
      JSON.stringify(this.state.alternativas)
    );
    data.append(
      "alternativas_cadastrar",
      JSON.stringify(this.state.novasAlternativas)
    );
    data.append("categorias", JSON.stringify(array));
    data.append("valor", "2");
    api
      .post("/questoes/update-questao", data)
      .then(res => {
        console.log(res.data);
        this.props.history.push(`/provas`)
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  mudou(e) {
    var parsedJsonArray = JSON.parse(e);
    this.state.inputedList.forEach(function iterator(item) {
      if (parsedJsonArray["id"] === item.id) {
        item.texto = parsedJsonArray["texto"];
        // console.log(item.texto);
        if (parsedJsonArray["correta"] === 1) {
          item.correta = 1;
        }
      }
      if (item.correta === 1) {
        if (parsedJsonArray["correta"] === 1) {
          if (parsedJsonArray["id"] != item.id) {
            item.correta = 0;
          }
        }
      }
    });

    this.setState({
      alternativas: this.state.inputedList
    });
  }

  removeFilho(e) {
    var parsedJsonArray = JSON.parse(e);
    var index = e;
    this.state.inputedList.splice(index, 1);

    this.setState({
      alternativas: this.state.inputedList
    });

    const data = new FormData();
    data.append("id", parsedJsonArray);

    api
      .post("/questoes/remove-alternativa", data)
      .then(res => {
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  salveiFilho(e) {
    var parsedJsonArray = JSON.parse(e);
    this.setState({
      novasAlternativas: parsedJsonArray
    });
  }

  render() {
    const {
      categoria_id,
      categorias,
      alternativas,
      inputList,
      novaAlternativa
    } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title="Editar questão"
            subtitle="Detalhe da questão"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <Form>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Questão</h6>
                </CardHeader>
                <CardBody className="pb-3">
                  <FormGroup>
                    <label htmlFor="username" style={{ fontWeight: "600" }}>
                      Título da questão
                    </label>
                    <input
                      value={this.state.titulo}
                      type="text"
                      onChange={this.onChangeTitulo}
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlfor="select" style={{ fontWeight: "600" }}>
                      Categorias
                    </label>
                    <Select
                      value={categoria_id}
                      options={categorias}
                      onChange={this.handleMultiChange}
                      isMulti
                    />
                  </FormGroup>
                  <FormGroup>
                    <label style={{ fontWeight: "600" }}>Opções</label>
                    <>
                      {alternativas.map((alternativa, index) => (
                        <>
                          <AlternativaQuestao
                            alternativa={alternativa}
                            changeFilho={this.mudou}
                            onRemoveFilho={this.removeFilho}
                          />
                        </> 
                      ))}
                    </>
                    <AdicionarAlternativa salveiFilho={this.salveiFilho} />
                  </FormGroup>
                </CardBody>
                <CardFooter style={{ borderTop: "1px #eee solid" }}>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6" align="right">
                      <Button
                        size="sm"
                        theme="white"
                        onClick={this.handleSubmit}
                      >
                        <i className="far fa-bookmark mr-1" />
                        Alterar questão
                      </Button>
                    </div>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(EditQuestao);
