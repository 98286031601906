import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";
import api from "../services/api";
import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import NewDraft from "./../components/blog/NewDraft";
import Discussions from "./../components/blog/Discussions";
import TopReferrals from "./../components/common/TopReferrals";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

class DashboardOverview extends React.Component {
  state = {
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    smallStats: [],
    semRealizar: 0,
    somaRealizado: 0
  };
  constructor(props) {
    super(props);

    this.interval = null;
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }

  componentDidMount() {
    if (this.props.location.state === true) {
      this.showAlert();
    }

    api
      .get("/geral/gerais", {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          smallStats: [
            {
              label: "Alunos cadastrados",
              value: res.data.estudantes.estudantes,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(0, 184, 216, 0.1)",
                  borderColor: "rgb(0, 184, 216)",
                  data: [1, 2, 1, 3, 5, 4, 7]
                }
              ]
            },
            {
              label: "Alunos aprovados",
              value: res.data.aprovados.aprovados,
              increase: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "6", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(23,198,113,0.1)",
                  borderColor: "rgb(23,198,113)",
                  data: [1, 2, 3, 3, 3, 4, 4]
                }
              ]
            },
            {
              label: "Alunos reprovados",
              value: res.data.reprovados.reprovados,
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(255,65,105,0.1)",
                  borderColor: "rgb(255,65,105)",
                  data: [2, 3, 3, 3, 4, 3, 3]
                }
              ]
            },
            {
              label: "Provas cadastradas",
              value: res.data.provas.provas,
              increase: false,
              decrease: true,
              chartLabels: [null, null, null, null, null, null, null],
              attrs: { md: "4", sm: "6" },
              datasets: [
                {
                  label: "Today",
                  fill: "start",
                  borderWidth: 1.5,
                  backgroundColor: "rgba(255,180,0,0.1)",
                  borderColor: "rgb(255,180,0)",
                  data: [1, 7, 1, 3, 1, 4, 8]
                }
              ]
            }
          ]
        });

        this.setState({
          somaRealizado: res.data.somaRealizado,
          semRealizar: res.data.semRealizar
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  render() {
    const { smallStats, somaRealizado, semRealizar } = this.state;
    return (
      <>
        <Alert className="mb-3" open={this.state.visible} theme="dark">
          Não autorizado.
        </Alert>

        <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Overview"
              subtitle="Dashboard"
              className="text-sm-left mb-3"
            />
          </Row>

          {/* Small Stats Blocks */}
          <Row>
            {smallStats.map((stats, idx) => (
              <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                />
              </Col>
            ))}
          </Row>

          <Row>
            {this.state.somaRealizado != 0 && (
              <>
                {/* Users Overview */}
                <Col lg="8" md="12" sm="12" className="mb-4">
                  <Card className="h-100">
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">Documentação do sistema</h6>
                    </CardHeader>
                    <CardBody>
                      <div className="row">
                        <div className="col-md-6 col-12 col-sm-12">
                          <Link
                            to="/documentacao-provas"
                            className={`${styles.cardlink} h-100`}
                          >
                            <article className={`${styles.blogcard} h-100`}>
                              <img
                                className={`${styles.postimage}`}
                                src="https://i.imgur.com/KEknR8H.png"
                              />
                              <div className={`${styles.articledetails}`}>
                                <h4 className={`${styles.postcategory}`}>
                                  Prova
                                </h4>
                                <h3 className={`${styles.posttitle}`}>
                                  Gerenciamento de provas
                                </h3>
                                <p className={`${styles.postdescription}`}>
                                  Gerenciamento de provas e seus itens:
                                  questões, categorias, alunos.
                                </p>
                              </div>
                            </article>
                          </Link>
                        </div>
                        <div className="col-md-6 col-12 col-sm-12">
                          <Link
                            to="/documentacao-categorias"
                            className={`${styles.cardlink} h-100`}
                          >
                            <article className={`${styles.blogcard} h-100`}>
                              <img
                                className={`${styles.postimage}`}
                                src="https://i.imgur.com/UXMT1nF.png"
                              />
                              <div className={`${styles.articledetails}`}>
                                <h4 className={`${styles.postcategory}`}>
                                  Categorias
                                </h4>
                                <h3 className={`${styles.posttitle}`}>
                                  Gerenciamento de categorias
                                </h3>
                                <p className={`${styles.postdescription}`}>
                                  Gerenciamento de categorias cadastradas no
                                  sistema.
                                </p>
                              </div>
                            </article>
                          </Link>
                        </div>
                      </div> 
                      <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col-md-6 col col-sm-12">
                          <Link
                            to="/documentacao-alunos"
                            className={`${styles.cardlink} h-100`}
                          >
                            <article className={`${styles.blogcard} h-100`}>
                              <img
                                className={`${styles.postimage}`}
                                src="https://i.imgur.com/mtvJmDX.png"
                              />
                              <div className={`${styles.articledetails}`}>
                                <h4 className={`${styles.postcategory}`}>
                                  Alunos
                                </h4>
                                <h3 className={`${styles.posttitle}`}>
                                  Gerenciamento de alunos
                                </h3>
                                <p className={`${styles.postdescription}`}>
                                  Gerenciamento de alunos cadastrados no
                                  sistema.
                                </p>
                              </div>
                            </article>
                          </Link>
                        </div>
                        <div className="col-md-6 col col-sm-12">
                          <Link
                            to="/documentacao-realizacao-prova"
                            className={`${styles.cardlink} h-100`}
                          >
                            <article className={`${styles.blogcard} h-100`}>
                              <img
                                className={`${styles.postimage}`}
                                src="https://i.imgur.com/OO7l8Bi.png"
                              />
                              <div className={`${styles.articledetails}`}>
                                <h4 className={`${styles.postcategory}`}>
                                  Realização de provas
                                </h4>
                                <h3 className={`${styles.posttitle}`}>
                                  Cada etapa da realização de provas
                                </h3>
                                <p className={`${styles.postdescription}`}>
                                  Etapa por etapa.
                                </p>
                              </div>
                            </article>
                          </Link>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                {/* Users by Device */}
                <Col lg="4" md="6" sm="12" className="mb-4">
                  {this.state.somaRealizado != 0 && (
                    <UsersByDevice
                      semRealizar={this.state.semRealizar}
                      realizado={this.state.somaRealizado}
                    />
                  )}
                </Col>
              </>
            )}
            {this.state.somaRealizado === 0 && (
              <Col lg="12" md="12" sm="12" className="mb-4">
                <Card className="h-100">
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Documentação do sistema</h6>
                  </CardHeader>
                  <CardBody>
                    <div className="row">
                      <div className="col-md-6 col-12 col-sm-12">
                        <Link
                          to="/documentacao-provas"
                          className={`${styles.cardlink} h-100`}
                        >
                          <article className={`${styles.blogcard} h-100`}>
                            <img
                              className={`${styles.postimage}`}
                              src="https://i.imgur.com/KEknR8H.png"
                            />
                            <div className={`${styles.articledetails}`}>
                              <h4 className={`${styles.postcategory}`}>
                                Prova
                              </h4>
                              <h3 className={`${styles.posttitle}`}>
                                Gerenciamento de provas
                              </h3>
                              <p className={`${styles.postdescription}`}>
                                Gerenciamento de provas e seus itens: questões,
                                categorias, alunos.
                              </p>
                            </div>
                          </article>
                        </Link>
                      </div>
                      <div className="col-md-6 col-12 col-sm-12">
                        <Link
                          to="/documentacao-categorias"
                          className={`${styles.cardlink} h-100`}
                        >
                          <article className={`${styles.blogcard} h-100`}>
                            <img
                              className={`${styles.postimage}`}
                              src="https://i.imgur.com/UXMT1nF.png"
                            />
                            <div className={`${styles.articledetails}`}>
                              <h4 className={`${styles.postcategory}`}>
                                Categorias
                              </h4>
                              <h3 className={`${styles.posttitle}`}>
                                Gerenciamento de categorias
                              </h3>
                              <p className={`${styles.postdescription}`}>
                                Gerenciamento de categorias cadastradas no
                                sistema.
                              </p>
                            </div>
                          </article>
                        </Link>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                      <div className="col-md-6 col col-sm-12">
                        <Link
                          to="/documentacao-usuarios"
                          className={`${styles.cardlink} h-100`}
                        >
                          <article className={`${styles.blogcard} h-100`}>
                            <img
                              className={`${styles.postimage}`}
                              src="https://i.imgur.com/mtvJmDX.png"
                            />
                            <div className={`${styles.articledetails}`}>
                              <h4 className={`${styles.postcategory}`}>
                                Alunos
                              </h4>
                              <h3 className={`${styles.posttitle}`}>
                                Gerenciamento de alunos
                              </h3>
                              <p className={`${styles.postdescription}`}>
                                Gerenciamento de alunos cadastrados no sistema.
                              </p>
                            </div>
                          </article>
                        </Link>
                      </div>
                      <div className="col-md-6 col col-sm-12">
                        <Link
                          to="/documentacao-usuarios"
                          className={`${styles.cardlink} h-100`}
                        >
                          <article className={`${styles.blogcard} h-100`}>
                            <img
                              className={`${styles.postimage}`}
                              src="https://i.imgur.com/OO7l8Bi.png"
                            />
                            <div className={`${styles.articledetails}`}>
                              <h4 className={`${styles.postcategory}`}>
                                Prova reprovado
                              </h4>
                              <h3 className={`${styles.posttitle}`}>
                                Gerenciamento de provas para reprovados
                              </h3>
                              <p className={`${styles.postdescription}`}>
                                Gerenciamento de provas para alunos reprovados.
                              </p>
                            </div>
                          </article>
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

export default DashboardOverview;
