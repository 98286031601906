import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../../services/api";

class AlternativaQuestao extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    alternativa: {
      id: null,
      correta: null,
      texto: null
    }
  };

  componentDidMount() {
    this.setState({
      alternativa: {
        id: this.props.alternativa.id,
        correta: this.props.alternativa.correta,
        texto: this.props.alternativa.texto
      }
    });
    this.handleRadioChangeAlternativa = this.handleRadioChangeAlternativa.bind(
      this
    );
    this.handleInputChangeAlternativa = this.handleInputChangeAlternativa.bind(
      this
    );
    this.handleRemoveInputedClick = this.handleRemoveInputedClick.bind(this)
  }

  handleRadioChangeAlternativa() {
    this.setState({
      alternativa: {
        correta: 1,
        id: this.state.alternativa.id,
        texto: this.state.alternativa.texto
      }
    });

    this.props.alternativa.correta = 1

    this.props.changeFilho(JSON.stringify(this.props.alternativa));
  }

  handleInputChangeAlternativa(e) {
      e.preventDefault()
    const texto = e.target.value;
    this.setState({
      alternativa: {
        id: this.state.alternativa.id,
        correta: this.state.alternativa.correta,
        texto: texto
      }
    });

    this.props.alternativa.texto = texto
    this.props.changeFilho(JSON.stringify(this.props.alternativa));
  }

  handleRemoveInputedClick(e) {
    console.log(e)
    this.props.onRemoveFilho(JSON.stringify(this.props.alternativa.id))
  }

  render() {
    return (
      <>
        <div className="box">
          <FormGroup>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="radio"
                    name="correta"
                    value={this.state.alternativa.id}
                    checked={this.props.alternativa.correta === 1}
                    onChange={this.handleRadioChangeAlternativa}
                  /> 
                </div>
              </div>
              <input
                value={this.props.alternativa.texto}
                type="text"
                className="form-control"
                onChange={this.handleInputChangeAlternativa}
              />
              <div class="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={() =>
                    this.handleRemoveInputedClick(this.state.alternativa.id)
                  }
                >
                  Remover
                </button>
              </div>
            </div>
          </FormGroup>
        </div>
      </>
    );
  }
}

export default withRouter(AlternativaQuestao);
