import React from "react";
import { Container, Button } from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";

class Errors extends React.Component {
  constructor(props) {
    super(props)
  }
  
  goToHome() {
    this.props.history.push("/")
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <div className="error">
          <div className="error__content">
            <h2>404</h2>
            <h3>Não encontrado!</h3>
            <p>Não encontramos a página, por favor, tente novamente.</p>
            <Button pill onClick={() => this.goToHome()}>&larr; Voltar</Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(Errors);
