import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormInput,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import moment from "moment";
import "../../../Turmas/styles.css";
import styles from "../../../Categorias/styles.module.css";

class DetalheProva extends React.Component {
  state = {
    id: "",
    texto: "",
    titulo: "",
    dataCadastro: "",
    duracaoMaxima: ""
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      id: this.props.id,
      titulo: this.props.titulo,
      texto: this.props.texto,
      dataCadastro: this.props.dataCadastro,
      duracaoMaxima: this.props.duracaoMaxima
    });

    console.log(this.state.texto);
  }

  setTimezone(e) {
    moment.locale("pt-br");
    return moment(e).format("DD/MM/YYYY");
  }

  render() {
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <div className="row">
            <div className="col-md-4 col-4">
              <h6 className="m-0">Detalhes da prova</h6>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <p style={{ textAlign: "justify" }}>
            <strong style={{ fontWeight: "600" }}>Descrição</strong>:
            <div dangerouslySetInnerHTML={{ __html: this.state.texto }} />
          </p>
          <p>
            <strong style={{ fontWeight: "600" }}>
              Duração máxima da prova
            </strong>
            : {this.state.duracaoMaxima} minutos
          </p>
          <p>
            <strong style={{ fontWeight: "600" }}>Data de cadastro</strong>:{" "}
            {this.setTimezone(this.state.dataCadastro)}
          </p>
        </CardBody>
        <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
          <Link
            to={{
              pathname: `/prova-edit/${this.state.id}`
            }}
            className="btn btn-white btn-sm"
            type="button"
          >
            <i className="far fa-bookmark mr-1" /> Editar prova
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(DetalheProva);
