import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../../services/api";
import { MdHighlightOff, MdCheckCircle } from "react-icons/md";
import ReactPaginate from "react-paginate";

class QuestaosListsReprovado extends React.Component {
  state = {
    questoes: [],
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.getQuestions();
  }

  getQuestions() {
    api
      .get(
        `/questoes/${this.props.id}/${this.props.prova}/alternativa-user-detail-reprovado`
      )
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          questoes: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(errors => {
      });
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    const currentState = this.state.active;
    this.setState({ active: !currentState });

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.getQuestions();
      }
    );
  };

  render() {
    const { questoes } = this.state;
    return (
      <Card small className="mb-4 h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">
            Questões respondidas{" "}
            {!this.state.questoes.lenght && (
              <Badge theme="dark">Prova ainda não realizada</Badge>
            )}
          </h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0" width="2%"></th>
                <th scope="col" className="border-0">
                  Título da questão
                </th>
                <th scope="col" className="border-0">
                  Resposta
                </th>
                <th scope="col" className="border-0" width="15%">
                  Pontuação
                </th>
              </tr>
            </thead>
            <tbody>
              {questoes.map(questao => (
                <>
                  <tr>
                    <td>
                      {questao.correcao === "1" && (
                        <MdCheckCircle
                          style={{
                            color: "#28a745",
                            fontSize: "24px"
                          }}
                        />
                      )}
                      {questao.correcao === "0" && (
                        <MdHighlightOff
                          style={{
                            color: "red",
                            fontSize: "24px"
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <strong>{questao.titulo}</strong>
                    </td>
                    <td>{questao.texto}</td>
                    <td>{questao.nota}.00 pontos de 2.00 pontos</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </CardBody>
        <CardFooter
          className="d-flex justify-content-center"
          style={{ borderTop: "1px #eee solid" }}
        >
          <ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Próximo"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          ></ReactPaginate>
        </CardFooter>
      </Card>
    );
  }
}

export default withRouter(QuestaosListsReprovado);
