import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import api from "../../services/api";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../Turmas/styles.css";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../Categorias/styles.module.css";
class Usuarios extends React.Component {
  state = {
    usuarios: [],
    open: false,
    nome: "",
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
    filteredData: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    number: []
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.usuarios.filter(el =>
        el.name.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  async receivedData() {
    this.setState({
      loading: true
    });
    await api
      .get("users/administrator")
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          usuarios: slice,
          loading: false
        });

        this.setState({
          filteredData: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          /*message: "Houve um erro na requisição, tente novamente.",
          theme: "danger"
        }, () => {
          this.showAlert();*/
        });
      });
  }

  async componentDidMount() {
    if (this.b64d(localStorage.getItem("type")) === "admin") {
      this.receivedData();

      if (this.props.location.state === true) {
        this.setState({
          message: "Usuário cadastrado com sucesso.",
          theme: "success"
        });
        this.showAlert();
      } else if (this.props.location.state === false) {
        this.setState({
          message: "Usuário alterado com sucesso.",
          theme: "success"
        });
        this.showAlert();
      }
    } else {
      this.props.history.push("/overview", true); 
    }
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  onChangeNome(e) {
    e.preventDefault();
    this.setState({
      nome: e.target.value
    });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { usuarios, loading, number } = this.state;
    const { filteredData } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Usuários"
            subtitle="Usuários cadastradas no sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6 className="m-0">Usuários</h6>
                  </div>
                  <div className="col-md-8 col-8 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <InputGroup size="md" seamless>
                          <InputGroupAddon type="prepend">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            placeholder="Pesquisar pelo nome do usuário"
                            style={{ fontSize: "14px" }}
                            onChange={this.handleSearchChange}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6 col-6">
                        <Link
                          to="/usuario-cadastro"
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="material-icons">add_circle_outline</i>{" "}
                          Cadastrar novo usuário
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loading === false ? (
                <>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            Função
                          </th>
                          <th scope="col" className="border-0">
                            E-mail registrado
                          </th>
                          <th scope="col" className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((usuario, index) => (
                          <tr key={usuario.id}>
                            <td>{index + 1}</td>
                            <td>{usuario.name}</td>
                            <td>
                              {(usuario.type === "admin" && (
                                <Badge theme="primary">Administrador</Badge>
                              )) || <Badge theme="success">Revisor</Badge>}
                            </td>
                            <td>
                              <a href={`mailto:${usuario.email}`}>
                                {usuario.email}
                              </a>
                            </td> 
                            <td className="d-flex justify-content-end">
                              <Link
                                to={{
                                  pathname: `/user-detalhe/${usuario.id}/edit`,
                                  user: {
                                    name: usuario.name,
                                    id: usuario.id,
                                    email: usuario.email,
                                    type: usuario.type
                                  }
                                }}
                                className="btn btn-white btn-sm"
                                type="button"
                              >
                                <i className="far fa-bookmark mr-1" /> Editar
                                usuário
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter style={{ borderTop: "1px #eee solid" }}>
                    <div
                      align="center"
                      className="d-flex justify-content-center"
                    >
                      <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </div>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Usuarios;
