import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import PageTitle from "../../../components/common/PageTitle";
import api from "../../../services/api";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../styles.module.css";
class EditCategoria extends React.Component {
  state = {
    nome: "",
    id: "",
    loading: false,
    loadingEdit: false,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await api
      .get(`/categorias/${this.props.match.params.id}`, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          id: res.data.id,
          nome: res.data.nome,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            loading: false,
            /*message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();*/
          }
        );
      });
  }

  onChangeNome(e) {
    e.preventDefault();
    this.setState({
      nome: e.target.value
    });
  }

  async handleSubmit() {
    this.setState({
      loadingEdit: true
    });
    const data = new FormData();
    data.append("nome", this.state.nome);
    await api
      .post(`categorias/${this.state.id}/update`, data, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          loadingEdit: false
        });

        this.props.history.push("/categorias", true);
      })
      .catch(error => {
        this.setState(
          {
            loadingEdit: false,
            message: "Houve um erro na requisição, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
        console.log(error);
      });
  }

  render() {
    const { nome, id, loading, loadingEdit } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Editar categoria"
            subtitle="Categoria cadastrada no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-3">
              {loading === false ? (
                <CardBody>
                  <Form className="add-new-post">
                    <FormGroup>
                      <label style={{ fontWeight: "600" }}>
                        Nome da categoria
                      </label>
                      <input
                        type="text"
                        value={this.state.nome}
                        onChange={this.onChangeNome}
                        className="form-control"
                      />
                    </FormGroup>

                    <FormGroup align="right">
                      <Button
                        onClick={this.handleSubmit}
                        disabled={loadingEdit === true}
                      >
                        {loadingEdit === true && (
                          <>
                            <div className="row">
                              <div
                                className="col-auto"
                                style={{ paddingLeft: "8px" }}
                              >
                                <CircleSpinner
                                  size={20}
                                  color="#fff"
                                  loading={loadingEdit}
                                ></CircleSpinner>
                              </div>
                              <div className="col">
                                <span
                                  style={{
                                    paddingTop: "4px",
                                    verticalAlign: "middle"
                                  }}
                                >
                                  Alterando categoria...
                                </span>
                              </div>
                            </div>
                          </>
                        )}{" "}
                        {loadingEdit === false && <>Alterar categoria</>}
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(EditCategoria);
