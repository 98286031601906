import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import "../styles.css";

class ProvaDocumentacao extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Gerenciamento de Provas"
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="8" md="8">
            <Accordion />
          </Col>
        </Row>
      </Container>
    );
  }
}

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          <li {...{ className: "accordion-list__item" }}>
            <AccordionItemCadastro />
            <AccordionItemListagem />
            <AccordionItemDetalhe />
          </li>
        </ul>
      </div>
    );
  }
}

class AccordionItemListagem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>
              Listagem de provas cadastradas
            </h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px", textAlign: "justify" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Para visualizar as provas cadastradas, clique no menu{" "}
                <u>Provas</u>. Nesta aba é possível filtrar as provas
                cadastradas pelo título da prova, no campo de busca.
                <br />
                <br />
                <span style={{ fontWeight: "600" }}>Convidar aluno</span>: Após
                o cadastro, utilize esse botão para convidar o aluno para
                realizar a prova. Ao selecionar o aluno e clicar em{" "}
                <u>convidar aluno</u>, automaticamente um e-mail de convite para
                prova será enviado ao aluno com as credenciais de acesso ao
                sistema.
                <br />
                <br />
                <span style={{ fontWeight: "600" }}>Gerar prova reprovado</span>
                : Esse botão gera uma cópia da prova atual para a versão para
                alunos reprovados.
                <br />
                <br />
                <span style={{ fontWeight: "600" }}>Detalhe da prova</span>:
                Clique no título da prova para visualizar o detalhe da prova.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class AccordionItemCadastro extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>Cadastrar prova</h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px", textAlign: "justify" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Para realizar o cadastro de uma nova prova, clique no menu{" "}
                <u>Provas</u>.<br />
                <br />
                Clique no botão <u>Cadastrar nova prova</u>.<br />
                <br />O formulário de cadastro de nova prova possui dois campos:{" "}
                <i>Título</i> e <i>Descrição</i>. Ambos dos campos são
                informações que são apresentadas ao aluno <u>antes</u> de
                começar a prova.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class AccordionItemDetalhe extends React.Component {
    state = {
      opened: false
    };
  
    render() {
      const { opened } = this.state;
  
      return (
        <>
          <div
            {...{
              className: `accordion-item, ${opened && "accordion-item--opened"}`,
              onClick: () => {
                this.setState({ opened: !opened });
              }
            }}
          >
            <div {...{ className: "accordion-item__line" }}>
              <h3 {...{ className: "accordion-item__title" }}>
                Detalhe da prova
              </h3>
              <span {...{ className: "accordion-item__icon" }} />
            </div>
            <div
              {...{ className: "accordion-item__inner" }}
              style={{ borderTop: "1px #eee solid" }}
            >
              <div
                {...{ className: "accordion-item__content" }}
                style={{ marginTop: "15px", textAlign: "justify" }}
              >
                <p {...{ className: "accordion-item__paragraph" }}>
                  Gráficos: quantidade de provas realizadas e resultados (aprovado e reprovado).<br /><br />
                  <img src="https://i.imgur.com/ceYtzJJ.png" className="img-fluid" /><br /><br />
                  Listagem de alunos vinculados à prova (para acessar os dados de desempenho do aluno, clique no nome do aluno).<br /><br />
                  <img src="https://i.imgur.com/Datg9G4.png" className="img-fluid" /><br /><br />
                  Listagem de questões vinculadas à prova.
                </p>
              </div>
            </div>
          </div>
        </>
      );
    }
  }

export default ProvaDocumentacao;
