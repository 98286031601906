import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormRadio,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox,
  Alert
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../services/api";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../Categorias/styles.module.css";

class CadastrarUser extends React.Component {
  state = {
    nome: "",
    email: "",
    password: "",
    confirmPassword: "",
    selectedFuncao: null,
    errors: "",
    hidden: true,
    text: "Show",
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    confirm: false
  };

  constructor(props) {
    super(props);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.onChangeFuncao = this.onChangeFuncao.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  onChangeNome(e) {
    e.preventDefault();
    this.setState({
      nome: e.target.value
    });
  }

  onChangeEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    e.preventDefault();
    this.setState({
      password: e.target.value
    });
  }

  onChangePasswordConfirm(e) {
    console.log(e.target.value);
    if (this.state.password != e.target.value) {
      this.setState({
        errors: "As senhas não conferem."
      });
    } else {
      this.setState({
        confirm: true,
        confirmPassword: e.target.value,
        errors: ""
      });
    }
  }

  onChangeFuncao(funcao) {
    console.log(funcao);
    this.setState({
      selectedFuncao: funcao
    });
  }

  toggleShow() {
    this.setState({
      hidden: !this.state.hidden
    });

    this.setState({
      text: "Esconder"
    });
  }

  makeid() {
    var text = "";
    text =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    return text;
  }

  async handleSubmit() {
    this.setState({
      loading: true
    });
    console.log("Entrei");
    const data = new FormData();
    data.append("name", this.state.nome);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("type", this.state.selectedFuncao);

    await api
      .post("users/register-user", data)
      .then(res => {
        this.setState({
          loading: false
        });
        this.props.history.push("/usuarios", true);
      })
      .catch(error => {
        this.setState(
          {
            loading: false,
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { loading, confirm } = this.state;
    // console.log("Senha" + this.makeid());
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Cadastrar usuário"
            subtitle="Cadastrar usuário no sistema"
            className="text-sm-left"
          />
        </Row>

        <Col lg="12" md="12">
          <div>
            <Alert
              className="mb-3"
              open={this.state.visible}
              theme={this.state.theme}
            >
              {this.state.message}
            </Alert>
          </div>
        </Col>
        <Col lg="12" md="12">
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Cadastrar novo usuário</h6>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Nome completo
                  </label>
                  <FormInput
                    id="username"
                    placeholder="Nome completo"
                    onChange={this.onChangeNome}
                  />
                </FormGroup>

                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    E-mail
                  </label>
                  <FormInput
                    id="username"
                    type="email"
                    placeholder="E-mail"
                    onChange={this.onChangeEmail}
                  />
                </FormGroup>

                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Senha
                  </label>
                  <InputGroup>
                    <FormInput
                      id="username"
                      type={this.state.hidden ? "password" : "text"}
                      value={this.state.password}
                      placeholder="Senha"
                      onChange={this.onChangePassword}
                    />
                    <Button squared onClick={this.toggleShow}>
                      {this.state.text}
                    </Button>
                  </InputGroup>
                  <div className="text-danger">{this.state.errors}</div>
                </FormGroup>

                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Digite a senha novamente
                  </label>
                  <FormInput
                    id="username"
                    type="password"
                    placeholder="Confirme a senha"
                    onChange={this.onChangePasswordConfirm}
                  />
                  {confirm === true && (
                    <div className="valid-feedback">As senhas conferem.</div>
                  )}
                </FormGroup>

                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Função
                  </label>
                  <FormRadio
                    name="fruit"
                    checked={this.state.selectedFuncao === "admin"}
                    onChange={() => {
                      this.onChangeFuncao("admin");
                    }}
                  >
                    Administrador
                  </FormRadio>
                  <FormRadio
                    name="fruit"
                    checked={this.state.selectedFuncao === "reviewer"}
                    onChange={() => this.onChangeFuncao("reviewer")}
                  >
                    Revisor
                  </FormRadio>
                </FormGroup>
              </Form>
            </CardBody>

            <CardFooter className="border-top" align="right">
              <Button
                theme="success"
                onClick={this.handleSubmit}
                disabled={loading === true}
              >
                {loading === true && (
                  <>
                    <div className="row">
                      <div className="col-auto" style={{ paddingLeft: "8px" }}>
                        <CircleSpinner
                          size={20}
                          color="#fff"
                          loading={loading}
                        ></CircleSpinner>
                      </div>
                      <div className="col">
                        <span
                          style={{ paddingTop: "4px", verticalAlign: "middle" }}
                        >
                          Cadastrando usuário...
                        </span>
                      </div>
                    </div>
                  </>
                )}{" "}
                {loading === false && <>Cadastrar usuário</>}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Container>
    );
  }
}

export default withRouter(CadastrarUser);
