import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import Editor from "../../../components/add-new-post/Editor";
import { Link } from "react-router-dom";
import PageTitle from "../../../components/common/PageTitle";
import api from "../../../services/api";
import ReactPaginate from "react-paginate";
import "../../Turmas/styles.css";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../Categorias/styles.module.css";

class ListagemAlunos extends React.Component {
  state = {
    alunos: [],
    nome: "",
    type: "",
    email: "",
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
    filteredData: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    loadingSuspender: false
  };

  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
  }

  async receivedData() {
    this.setState({
      loading: true
    });
    await api
      .get("users/list/students")
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          alunos: slice,
          loading: false
        });

        this.setState({
          filteredData: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        this.setState(
          {
            //message: "Houve um erro na requisição, tente novamente.",
            //theme: "danger",
            loading: false
          }/*,
          () => {
            this.showAlert();
          }*/
        );
      });
  }

  async componentDidMount() {
    this.receivedData();

    if (this.props.location.state === true) {
      this.setState({
        message: "Cadastro alterado com sucesso.",
        theme: "success"
      });
      this.showAlert();
    }
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.alunos.filter(el =>
        el.name.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  async suspenderCadastro(id) {
    await api
      .post(`users/suspend/${id}`, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          message: "Cadastro suspenso com sucesso.",
          theme: "danger"
        });
        this.receivedData();
        this.showAlert();
      })
      .catch(error => {
        this.setState({
          message: "Ops! Algo saiu errado, tente novamente.",
          theme: "danger"
        });
        this.showAlert();
      });
  }

  async reativarCadastro(id) {
    await api
      .post(`users/reativar/${id}`, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          message: "Cadastro reativado com sucesso.",
          theme: "success"
        });
        this.receivedData();
        this.showAlert();
      })
      .catch(error => {
        this.setState({
          message: "Ops! Algo saiu errado, tente novamente.",
          theme: "danger"
        });
        this.showAlert();
      });
  }

  dismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { alunos, loading } = this.state;
    const { filteredData } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Alunos"
            subtitle="Alunos cadastrados no sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6 className="m-0">Alunos</h6>
                  </div>
                  <div className="col-md-8 col-8 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <InputGroup size="md" seamless>
                          <InputGroupAddon type="prepend">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            placeholder="Pesquisar pelo nome da categoria"
                            style={{ fontSize: "14px" }}
                            onChange={this.handleSearchChange}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6 col-6">
                        <Link
                          to="/aluno-cadastro"
                          className="btn btn-primary"
                          type="button"
                        >
                          <i className="material-icons">add_circle_outline</i>{" "}
                          Cadastrar novo aluno
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loading === false ? (
                <>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Nome
                          </th>
                          <th scope="col" className="border-0">
                            E-mail
                          </th>
                          <th scope="col" className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((aluno, index) => (
                          <tr key={aluno.id}>
                            <td>{index + 1}</td>
                            <td>
                              {aluno.name}{" "}
                              {aluno.suspenso === "1" && (
                                <Badge theme="danger">Cadastro suspenso</Badge>
                              )}
                            </td>
                            <td>
                              <a href={`mailto:${aluno.email}`}>
                                {aluno.email}
                              </a>
                            </td>
                            <td className="d-flex justify-content-end">
                              {aluno.suspenso === null && (
                                <Button
                                  size="sm"
                                  theme="danger"
                                  onClick={() =>
                                    this.suspenderCadastro(aluno.id)
                                  }
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Suspender cadastro
                                </Button>
                              )}
                              {aluno.suspenso === "1" && (
                                <Button
                                  size="sm"
                                  theme="success"
                                  onClick={() =>
                                    this.reativarCadastro(aluno.id)
                                  }
                                >
                                  <i className="far fa-bookmark mr-1" />
                                  Reativar cadastro
                                </Button>
                              )}
                              <Link
                                to={{
                                  pathname: `/aluno-detalhe/${aluno.id}/edit`,
                                  aluno: {
                                    name: aluno.name,
                                    email: aluno.email,
                                    id: aluno.id
                                  }
                                }}
                                className="btn btn-white btn-sm"
                                style={{ marginLeft: "15px" }}
                              >
                                <i className="far fa-bookmark mr-1" />
                                Editar dados do aluno
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter style={{ borderTop: "1px #eee solid" }}>
                    <div
                      align="center"
                      className="d-flex justify-content-center"
                    >
                      <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </div>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ListagemAlunos;
