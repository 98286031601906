import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  InputGroupAddon,
  InputGroup,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import { MdHighlightOff, MdCheckCircle } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../../Categorias/styles.module.css";

class QuestaosList extends React.Component {
  state = {
    questoes: [],
    loadingQuestoes: false,
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0
  };

  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handlePageClickQuestion = this.handlePageClickQuestion.bind(this);
  }

  onChangeConsideracao(e) {
    e.preventDefault();
    this.setState({
      consideracao: e.target.value
    });
  }

  componentDidMount() {
    this.getQuestions();
  }

  getQuestions() {
    this.setState({
      loadingQuestoes: true
    });
    api
      .get(
        `/questoes/${this.props.id}/${this.props.prova}/alternativa-user-detail`
      )
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          questoes: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          loadingQuestoes: false
        });
      })
      .catch(errors => {
        console.log(errors);
        this.setState({
          loadingQuestoes: false
        });
      });
  }

  handlePageClickQuestion() {
    const selectedPage = this.state.currentPage + 1;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.getQuestions();
      }
    );
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.getQuestions();
      }
    );
  };

  render() {
    const { questoes, loadingQuestoes } = this.state;
    return (
      <>
        {" "}
        <Card small className="mb-4 h-100">
          <CardHeader className="border-bottom">
            <h6 className="m-0">
              Questões respondidas{" "}
              {this.state.questoes.lenght === 0 && (
                <Badge theme="dark">Prova ainda não realizada</Badge>
              )}
            </h6>
          </CardHeader>
          {loadingQuestoes === false ? (
            <>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0" width="2%"></th>
                      <th scope="col" className="border-0">
                        Título da questão
                      </th>
                      <th scope="col" className="border-0">
                        Resposta
                      </th>
                      <th scope="col" className="border-0" width="15%">
                        Pontuação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questoes.map(questao => (
                      <>
                        {this.props.status != null && (
                          <tr>
                            <td>
                              {questao.correcao === "1" && (
                                <MdCheckCircle
                                  style={{
                                    color: "#28a745",
                                    fontSize: "24px"
                                  }}
                                />
                              )}
                              {questao.correcao === "0" && (
                                <MdHighlightOff
                                  style={{
                                    color: "red",
                                    fontSize: "24px"
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <strong>{questao.titulo}</strong>
                            </td>
                            <td>{questao.texto}</td>
                            <td>{questao.nota}.00 pontos de 2.00 pontos</td>
                          </tr>
                        )}
                        {this.props.status === null && (
                          <tr>
                            <td>Prova não realizada</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </CardBody>
              <CardFooter
                className="d-flex justify-content-center"
                style={{ borderTop: "1px #eee solid" }}
              >
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </CardFooter>
            </>
          ) : (
            <div className={`${styles.card}`}></div>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(QuestaosList);
