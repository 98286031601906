import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  FormRadio,
  CardFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import api from "../../../services/api";
import { Link, useHistory, withRouter } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../Categorias/styles.module.css";

class EditUser extends React.Component {
  state = {
    name: "",
    id: "",
    email: "",
    type: "",
    password: "",
    passwordConfirm: "",
    selectedFuncao: "",
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    loadingEdit: false,
    errors: "",
    text: "Show",
    hidden: true
  };

  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.onChangeFuncao = this.onChangeFuncao.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow() {
    this.setState({
      hidden: !this.state.hidden
    });

    this.setState({
      text: "Esconder"
    });
  }
  
  async componentDidMount() {
    this.setState({
      loading: true
    });
    await api
      .get(`/users/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          type: res.data.type,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          /*message: "Houve um erro na requisição, tente novamente.",
          theme: "danger"
        }, () => {
          this.showAlert();*/
        });
      });
  }

  onChangeName(e) {
    e.preventDefault();
    this.setState({
      name: e.target.value
    });
  }

  onChangeType(e) {
    e.preventDefault();
    this.setState({
      type: e.target.value
    });
  }

  onChangeEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    e.preventDefault();
    this.setState({
      password: e.target.value
    });
  }

  onChangePasswordConfirm(e) {
    console.log(e.target.value);
    if (this.state.password != e.target.value) {
      this.setState({
        errors: "As senhas não conferem."
      });
    } else {
      this.setState({
        passwordConfirm: e.target.value,
        errors: ""
      });
    }
  }

  onChangeFuncao(funcao) {
    console.log(funcao);
    this.setState({
      selectedFuncao: funcao
    });
  }

  async handleSubmit() {
    this.setState({
      loadingEdit: true
    });
    const data = new FormData();

    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("type", this.state.type);
    data.append("id", this.state.id);
    await api
      .post(`users/${this.state.id}/update`, data)
      .then(res => {
        this.setState({
          loadingEdit: false
        });
        this.props.history.push("/usuarios", false);
      })
      .catch(error => {
        this.setState({
          message: "Ops! Algo saiu errado, tente novamente.",
          theme: "danger",
          loadingEdit: false
        }, () => {
          this.showAlert();
        });
        
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { loading, loadingEdit } = this.state;
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Editar usuário"
            subtitle="Detalhe do cadastro"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-3">
              {loading === false ? (
                <CardBody>
                  <Form className="add-new-post">
                    <FormGroup>
                      <label style={{ fontWeight: "600" }}>Nome completo</label>
                      <input
                        type="text"
                        value={this.state.name}
                        onChange={this.onChangeName}
                        className="form-control"
                      />
                    </FormGroup>

                    <FormGroup>
                      <label style={{ fontWeight: "600" }}>Email</label>
                      <input
                        type="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        className="form-control"
                      />
                    </FormGroup>

                    <FormGroup>
                      <label style={{ fontWeight: "600" }}>Senha</label>
                      <InputGroup>
                        <FormInput
                          id="username"
                          type={this.state.hidden ? "password" : "text"}
                          value={this.state.password}
                          placeholder="Senha"
                          onChange={this.onChangePassword}
                        />
                        <Button squared onClick={this.toggleShow}>
                          {this.state.text}
                        </Button>
                      </InputGroup>
                      <div className="text-danger">{this.state.errors}</div>
                    </FormGroup>

                    <FormGroup>
                      <label style={{ fontWeight: "600" }}>
                        Confirmar senha
                      </label>
                      <FormInput
                        id="username"
                        type="password"
                        placeholder="Confirme a senha"
                        onChange={this.onChangePasswordConfirm}
                      />
                    </FormGroup>

                    <FormGroup>
                      <label htmlFor="username" style={{ fontWeight: "600" }}>
                        Função
                      </label>
                      <FormRadio
                        name="fruit"
                        checked={this.state.type === "admin"}
                        onChange={() => {
                          this.onChangeFuncao("admin");
                        }}
                      >
                        Administrador
                      </FormRadio>
                      <FormRadio
                        name="fruit"
                        checked={this.state.type === "reviewer"}
                        onChange={() => this.onChangeFuncao("reviewer")}
                      >
                        Revisor
                      </FormRadio>
                    </FormGroup>

                    <FormGroup align="right">
                      <Button
                        onClick={this.handleSubmit}
                        disabled={loadingEdit === true}
                      >
                        {loadingEdit === true && (
                          <>
                            <div className="row">
                              <div
                                className="col-auto"
                                style={{ paddingLeft: "8px" }}
                              >
                                <CircleSpinner
                                  size={20}
                                  color="#fff"
                                  loading={loadingEdit}
                                ></CircleSpinner>
                              </div>
                              <div className="col">
                                <span
                                  style={{
                                    paddingTop: "4px",
                                    verticalAlign: "middle"
                                  }}
                                >
                                  Alterando dados...
                                </span>
                              </div>
                            </div>
                          </>
                        )}{" "}
                        {loadingEdit === false && <>Alterar usuário</>}
                      </Button>
                    </FormGroup>
                  </Form>
                </CardBody>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(EditUser);
