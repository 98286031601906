import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { logout } from "../../../../services/auth";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  async componentDidMount() {

  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  removeToken() {
    logout();
  }

  b64e(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  }
  
  b64d(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    }).join(''));
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">{" "}
          <span className="d-none d-md-inline-block" style={{marginTop: '10px'}}>
            {this.b64d(localStorage.getItem("user")) ? this.b64d(localStorage.getItem("user")) : null}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Perfil
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} onClick={() => this.removeToken()} to="/sign-in" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Sair
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
