import React from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import { login } from "../../services/auth";
import { fazHash, desfazHash } from "../../services/hash";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody
} from "shards-react";
import styled from "styled-components";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../Categorias/styles.module.css";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Form = styled.form`
  width: 400px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 200px;
    margin: 10px 0 30px;
  }
  p {
    color: #ff3333;
    margin-bottom: 15px;
    border: 1px solid #ff3333;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
  input {
    &::placeholder {
      color: #999;
    }
  }
  button {
    color: #fff;
    height: 40px;
    border-radius: 0px;
    width: 100%;
    font-family: "Roboto";
    text-transform: none;
    font-weight: 600;
  }
  a {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    color: #999;
    text-decoration: none;
  }
`;

class SignIn extends React.Component {
  state = {
    email: "",
    password: "",
    error: "",
    loading: false
  };

  constructor(props) {
    super(props);

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  handleSignIn = async e => {
    this.setState({
      loading: true
    });
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("login-admin", { email, password }, {
          credentials: 'include'
        });
        localStorage.setItem("user", fazHash(response.data.name));
        localStorage.setItem("email", fazHash(response.data.email));
        localStorage.setItem("type", fazHash(response.data.type));
        localStorage.setItem("type_log", response.data.type);
        localStorage.setItem("id", fazHash(response.data.id));
        login(response.data.access_token);
        this.setState({
          loading: false
        });
        this.props.history.push("/");
      } catch (err) {
        this.setState({
          loading: false,
          error: "Houve um problema com o login, verifique suas credenciais."
        });
      }
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <Container style={{ backgroundColor: "#755390" }}>
        <Form onSubmit={this.handleSignIn}>
          <img
            className="img-fluid"
            src="https://quantikacoaching.com.br/wp-content/uploads/2018/01/formacao.png"
          ></img>
          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={e => this.setState({ email: e.target.value })}
            className="form-control"
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={e => this.setState({ password: e.target.value })}
            className="form-control"
            style={{ marginTop: "10px" }}
          />
          <Button
            color="purple"
            type="submit"
            style={{ marginTop: "20px" }}
            disabled={loading === true}
          >
            {loading === true && (
              <>
                <div className="row">
                  <div className="col-auto" style={{ paddingLeft: "8px" }}>
                    <CircleSpinner
                      size={20}
                      color="#fff"
                      loading={loading}
                    ></CircleSpinner>
                  </div>
                  <div className="col">
                    <span
                      style={{ paddingTop: "4px", verticalAlign: "middle" }}
                    >
                      Realizando login...
                    </span>
                  </div>
                </div>
              </>
            )}{" "}
            {loading === false && <>Entrar</>}
          </Button>
        </Form>
      </Container>
    );
  }
}

export default SignIn;
