export default function() {
  return [
    {
      title: "Dashboard",
      to: "/overview",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Alunos",
      htmlBefore: '<i class="material-icons">account_box</i>',
      to: "/alunos",
    },
    {
      title: "Categorias",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/categorias",
    },
    {
      title: "Provas",
      htmlBefore: '<i class="material-icons">sticky_note_2</i>',
      to: "/provas",
    },
    {
      title: "Provas (Reprovados)",
      htmlBefore: '<i class="material-icons">bookmarks</i>',
      to: "/provas-reprovados",
    },
    {
      title: "Usuários",
      htmlBefore: '<i class="material-icons">assignment_ind</i>',
      to: "/usuarios",
    },
    {
      title: "Documentação do sistema",
      htmlBefore: '<i class="material-icons">info</i>',
      to: "/documentacao",
    }
  ];
}
