import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  Badge,
  CardFooter,
  Alert,
  CardImg,
  CardTitle
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import "../styles.css";

class AlunosDocumentacao extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Alunos"
            subtitle="Documentação das funcionalidades do sistema"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col lg="12" md="12">
            <Accordion />
          </Col>
        </Row>
      </Container>
    );
  }
}

class Accordion extends React.Component {
  render() {
    return (
      <div {...{ className: "wrapper" }}>
        <ul {...{ className: "accordion-list" }}>
          <li {...{ className: "accordion-list__item" }}>
            <AccordionItemListagem />
          </li>
        </ul>
      </div>
    );
  }
}

class AccordionItemListagem extends React.Component {
  state = {
    opened: false
  };

  render() {
    const { opened } = this.state;

    return (
      <>
        <div
          {...{
            className: `accordion-item, ${opened && "accordion-item--opened"}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <div {...{ className: "accordion-item__line" }}>
            <h3 {...{ className: "accordion-item__title" }}>
              Dados de desempenho do aluno
            </h3>
            <span {...{ className: "accordion-item__icon" }} />
          </div>
          <div
            {...{ className: "accordion-item__inner" }}
            style={{ borderTop: "1px #eee solid" }}
          >
            <div
              {...{ className: "accordion-item__content" }}
              style={{ marginTop: "15px" }}
            >
              <p {...{ className: "accordion-item__paragraph" }}>
                Gráfico de desempenho do aluno: status (aprovado ou reprovado), número de respostas corretas e incorretas (gráfico), tempo de prova e pontuação. <br />
                <br />
                <img
                  src="https://i.imgur.com/h84CWFx.png"
                  className="img-fluid"
                />
                <br />
                <br />
                Questões respondidas: pontuação, questão, alternativa escolhida, status (correta ou incorreta).<br /><br />
                <img src="https://i.imgur.com/yPTxk7s.png" className="img-fluid" /><br /><br />
                Documentação enviada pelo aluno (caso seja aprovado na prova), com opções para aprovação ou reprovação da documentação.<br /><br />
                <img src="https://i.imgur.com/EzEtshe.png" className="img-fluid" /><br /><br />
                Opção: caso a documentação seja reprovada será exibido a opção para enviar um segundo e-mail em relação a documentação reprovada adicionando considerações.<br /><br />
                <img src="https://i.imgur.com/mhC7BZw.png" className="img-fluid" /><br /><br />
                Após enviado o e-mail com as considerações, será possível consultar no sistema as considerações enviadas. <br /><br />
                <img src="https://i.imgur.com/BJdm6xs.png" className="img-fluid" /><br /><br />
                Opção documentação reprovada: será exibido uma nova tabela com os dados do segundo envio da documentação (também com opção de reprovação ou aprovação da documentação). <br /><br />
                <img src="https://i.imgur.com/UPjHVbS.png" className="img-fluid" />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AlunosDocumentacao;
