import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormInput,
  Alert
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../services/api";
import "../../Turmas/styles.css";
import styles from "../../Categorias/styles.module.css";
import GraficoRealizacaoReprovado from "./GraficoRealizacaoReprovado";
import GraficoResultadoReprovado from "./GraficoResultadoReprovado";
import AlunosListsReprovado from "./AlunosListsReprovado";
import QuestoesListsReprovado from "./QuestoesListsReprovado";

class ProvaReprovado extends React.Component {
  state = {
    id: "",
    texto: "",
    titulo: "",
    dataCadastro: "",
    duracaoMaxima: "",
    naorealizado: null,
    realizado: null,
    aprovados: null,
    reprovados: null,
    totalalunos: null
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    await api
      .get(`/provas-reprovados/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          id: res.data.id,
          titulo: res.data.titulo,
          texto: res.data.texto,
          dataCadastro: res.data.dataCadastro,
          duracaoMaxima: res.data.duracaoMaxima
        });
      })
      .catch(error => {});

    this.getGraphicos();
  }

  getGraphicos() {
    api
      .get(`/geral/prova-reprovado/${this.props.match.params.id}`)
      .then(res => {
        console.log(res.data.realizado.realizado);
        this.setState({
          aprovados: res.data.aprovados.aprovados,
          naorealizado: res.data.naorealizado.naorealizado,
          realizado: res.data.realizado.realizado,
          totalalunos: res.data.totalalunos.totalalunos,
          reprovados: res.data.reprovados.reprovados
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="12"
            title={this.state.titulo}
            subtitle="Prova"
            className="text-sm-left"
          />
        </Row>

        <Row>
          {this.state.realizado != null && (
            <>
              <div className="col-md-3">
                {this.state.realizado != null && (
                  <GraficoRealizacaoReprovado
                    realizado={this.state.realizado}
                    naorealizado={this.state.naorealizado + 1}
                  />
                )}
              </div>
              <div className="col-md-3">
                {this.state.aprovados != null && (
                  <GraficoResultadoReprovado
                    aprovados={this.state.aprovados + 1}
                    reprovados={this.state.reprovados}
                  />
                )}
              </div>
              <div className="col-md-6">
                <AlunosListsReprovado id={this.props.match.params.id} />
              </div>
            </>
          )}
          {this.state.realizado === null && (
            <>
              <div className="col-md-12">
                <AlunosListsReprovado id={this.props.match.params.id} />
              </div>
            </>
          )}
        </Row>

        <Row style={{ marginTop: "35px" }}>
          <Col lg="12" md="12">
            <QuestoesListsReprovado id={this.props.match.params.id} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ProvaReprovado);
