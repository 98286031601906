import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert,
  Badge
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import api from "../../services/api";
import { Link } from "react-router-dom";

class Emails extends React.Component {
  state = {
    emails: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  async receivedData() {
    await api
      .get("emails")
      .then(res => {
        this.setState({
          emails: res.data
        });
      })
      .catch(function(error) {
        console.log(error.response);
      });
  }

  async componentDidMount() {
    this.receivedData();
    if (this.props.location.state === true) {
      this.setState({
        message: "E-mail alterado com sucesso.",
        theme: "success"
      });
      this.showAlert();
    }
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    const { emails } = this.state;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="E-mails"
            subtitle="E-mails cadastrados no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="m-0">E-mails</h6>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        E-mail
                      </th>
                      <th scope="col" className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {emails.map(email => (
                      <tr key={email.id}>
                        <td>{email.id}</td>
                        <td>
                          <h6>
                            {email.assunto}
                            {"  "}{" "}
                            <Badge pill theme="secondary">
                              {email.funcao}
                            </Badge>
                          </h6>
                        </td>
                        <td className="d-flex justify-content-end">
                          <Link
                            to={{
                              pathname: `email/${email.id}`
                            }}
                            className="btn btn-white btn-sm"
                            type="button"
                          >
                            <i className="far fa-bookmark mr-1" /> Editar e-mail
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Emails;
