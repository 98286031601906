import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  FormSelect, 
  CardFooter,
  Alert
} from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import api from "../../../services/api";
import "react-quill/dist/quill.snow.css";
import "../../../assets/quill.css";
import ReactQuill from "react-quill";
import { reduceHooks } from "react-table";
import { withRouter } from "react-router-dom";

class EditEmail extends React.Component {
  state = {
    id: "",
    assunto: "",
    funcao: "",
    corpo: "",
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.onChangeAssunto = this.onChangeAssunto.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
  }

  async componentDidMount() {
    await api
      .get(`/emails/${this.props.match.params.id}`)
      .then(res => {
        this.setState({
          id: res.data.id,
          funcao: res.data.funcao,
          assunto: res.data.assunto,
          corpo: res.data.corpo
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  onChangeAssunto(e) {
    e.preventDefault();
    this.setState({
      assunto: e.target.value
    });
  }

  handleQuillChange = value => {
    console.log(value)
    this.setState({
      corpo: value
    });
  };

  processUpload() {
    const data = new FormData();

    data.append("assunto", this.state.assunto);
    data.append("corpo", this.state.corpo);

    api
      .post(`emails/update/${this.state.id}`, data)
      .then(response => {
        this.props.history.push("/emails", false);
      })
      .catch(error => {
        this.setState({
          message: "Ops! Algo saiu errado, tente novamente.",
          theme: "danger"
        });
        this.showAlert();
      });
  }

  showAlert() {
    console.log("entrei");
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Editar e-mail"
            subtitle="E-mail cadastrado no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-3">
              <CardBody>
                <Form className="add-new-post">
                  <FormGroup>
                    <label style={{ fontWeight: "600" }}>
                      Assunto do e-mail
                    </label>
                    <input
                      type="text"
                      value={this.state.assunto}
                      onChange={this.onChangeAssunto}
                      className="form-control"
                    />
                  </FormGroup>

                  <FormGroup>
                    <label style={{ fontWeight: "600" }}>Corpo do e-mail</label>
                    <ReactQuill
                      className="add-new-post__editor mb-1"
                      onChange={this.handleQuillChange}
                      value={this.state.corpo}
                    />
                  </FormGroup>

                  <FormGroup align="right">
                    <Button onClick={() => this.processUpload()}>
                      Alterar e-mail
                    </Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(EditEmail);
