export const TOKEN_KEY = "@ici-Token";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem("type_log") != "student";
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user")
    localStorage.removeItem("email")
    localStorage.removeItem("type")
    localStorage.removeItem("id")
    localStorage.removeItem("type_log")
}