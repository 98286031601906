import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  FormRadio,
  Badge,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormCheckbox,
  Alert
} from "shards-react";
import api from "../../services/api";
import { CircleSpinner } from "react-spinners-kit";
import styles from "../../pages/Categorias/styles.module.css";
class UserAccountDetails extends React.Component {
  state = {
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    type: "",
    id: "",
    selectedFuncao: "",
    loading: false,
    loadingEdit: false,
    text: "Show",
    hidden: true,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: ""
  };

  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
    this.onChangeFuncao = this.onChangeFuncao.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    // this.props.location.alert = false;
  }

  toggleShow() {
    this.setState({
      hidden: !this.state.hidden
    });

    this.setState({
      text: "Esconder"
    });
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    api
      .get(`/users/${this.b64d(localStorage.getItem("id"))}`)
      .then(res => {
        this.setState({
          name: res.data.name,
          email: res.data.email,
          id: res.data.id,
          type: res.data.type,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger",
            loading: false
          },
          () => {
            this.showAlert();
          }
        );
      });
  }

  onChangeName(e) {
    e.preventDefault();
    console.log(e.target.value);
    this.setState({
      name: e.target.value
    });
  }

  onChangeType(e) {
    e.preventDefault();
    this.setState({
      type: e.target.value
    });
  }

  onChangeEmail(e) {
    e.preventDefault();
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    e.preventDefault();
    this.setState({
      password: e.target.value
    });
  }

  onChangePasswordConfirm(e) {
    if (this.state.password != e.target.value) {
      this.setState({
        errors: "As senhas não conferem."
      });
    } else {
      this.setState({
        passwordConfirm: e.target.value,
        errors: ""
      });
    }
  }

  onChangeFuncao(funcao) {
    console.log(funcao);
    this.setState({
      selectedFuncao: funcao
    });
  }

  async handleSubmit() {
    this.setState({
      loadingEdit: true
    });
    const data = new FormData();

    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("id", this.state.id);
    await api
      .post(`users/${this.state.id}/update-perfil`, data)
      .then(res => {
        this.setState({
          loadingEdit: false
        });
        this.props.history.push("/");
      })
      .catch(error => {
      });
  }

  b64e(str) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  }

  b64d(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function(c) {
          return "%" + c.charCodeAt(0).toString(16);
        })
        .join("")
    );
  }

  render() {
    const { loading, loadingEdit } = this.state;
    return (
      <>
        <Col lg="12" md="12">
          <div>
            <Alert
              className="mb-3"
              open={this.state.visible}
              theme={this.state.theme}
            >
              {this.state.message}
            </Alert>
          </div>
        </Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">
              Detalhes da conta{" "}
              {(this.state.type === "admin" && (
                <Badge theme="primary">Administrador</Badge>
              )) || <Badge theme="success">Revisor</Badge>}
            </h6>
          </CardHeader>
          {loading === false ? (
            <>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          {/* First Name */}
                          <Col md="6" className="form-group">
                            <label
                              htmlFor="feFirstName"
                              style={{ fontWeight: "600" }}
                            >
                              Nome
                            </label>
                            <input
                              type="text"
                              value={this.state.name}
                              onChange={this.onChangeName}
                              className="form-control"
                            />
                          </Col>
                          {/* Last Name */}
                          <Col md="6" className="form-group">
                            <label
                              htmlFor="feEmail"
                              style={{ fontWeight: "600" }}
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row form>
                          {/* Email */}
                          <Col md="6" className="form-group">
                            <label
                              htmlFor="fePassword"
                              style={{ fontWeight: "600" }}
                            >
                              Senha
                            </label>
                            <InputGroup>
                              <FormInput
                                id="username"
                                type={this.state.hidden ? "password" : "text"}
                                value={this.state.password}
                                placeholder="Senha"
                                onChange={this.onChangePassword}
                              />
                              <Button squared onClick={this.toggleShow}>
                                {this.state.text}
                              </Button>
                            </InputGroup>
                            <div className="text-danger">
                              {this.state.errors}
                            </div>
                          </Col>
                          {/* Password */}
                          <Col md="6" className="form-group">
                            <label
                              htmlFor="fePassword"
                              style={{ fontWeight: "600" }}
                            >
                              Confirmar senha
                            </label>
                            <FormInput
                              id="username"
                              type="password"
                              placeholder="Confirme a senha"
                              onChange={this.onChangePasswordConfirm}
                            />
                          </Col>
                        </Row>
                        <div align="right">
                          <Button
                            onClick={this.handleSubmit}
                            disabled={loadingEdit === true}
                          >
                            {loadingEdit === true && (
                              <>
                                <div className="row">
                                  <div
                                    className="col-auto"
                                    style={{ paddingLeft: "8px" }}
                                  >
                                    <CircleSpinner
                                      size={20}
                                      color="#fff"
                                      loading={loadingEdit}
                                    ></CircleSpinner>
                                  </div>
                                  <div className="col">
                                    <span
                                      style={{
                                        paddingTop: "4px",
                                        verticalAlign: "middle"
                                      }}
                                    >
                                      Atualizando dados...
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}{" "}
                            {loadingEdit === false && <>Atualizar conta</>}
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </>
          ) : (
            <div className={`${styles.card}`}></div>
          )}
        </Card>
      </>
    );
  }
}

export default UserAccountDetails;
