import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Button,
  CardDeck,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormInput,
  Alert
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import ReactPaginate from "react-paginate";
import "../../../Turmas/styles.css";
import styles from "../../../Categorias/styles.module.css";

class QuestoesListsReprovado extends React.Component {
  state = {
    questoes: [],
    offset: 0,
    data: [],
    perPage: 12,
    currentPage: 0,
    filteredData: [],
    loadingQuestoes: false
  };

  constructor(props) {
    super(props);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  async receivedData() {
    this.setState({
      loadingQuestoes: true
    });
    await api
      .get(`questoes/${this.props.id}/list-reprovado`)
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          questoes: slice,
          loadingQuestoes: false
        });

        this.setState({
          filteredData: slice
        });

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage)
        });
      })
      .catch(error => {
        console.log(error.response);
        this.setState({
          loadingQuestoes: false
        });
      });
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.questoes.filter(el =>
        el.titulo.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  async componentDidMount() {
    this.receivedData();
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  render() {
    const { questoes, loadingQuestoes, filteredData } = this.state;
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <div className="row">
            <div className="col-md-4 col-4">
              <h6 className="m-0">
                Questões vinculadas à prova {this.props.prova}
              </h6>
            </div>
            <div className="col-md-8 col-8 d-flex justify-content-end">
              <div className="row">
                <div className="col-md-6 col-6">
                  <InputGroup size="md" seamless>
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      placeholder="Pesquisar pelo título da questão"
                      style={{ fontSize: "14px" }}
                      onChange={this.handleSearchChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-6 col-6">
                  <Link
                    to={{
                      pathname: `/${this.props.id}/questao-cadastro`,
                      prova: {
                        id: this.state.id
                      }
                    }}
                    className="btn btn-primary"
                    type="button"
                  >
                    <i className="material-icons">add_circle_outline</i>{" "}
                    Cadastrar nova questão
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardHeader>
        {loadingQuestoes === false ? (
          <>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      Título da questão
                    </th>
                    <th scope="col" className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(questao => (
                    <tr key={questao.id}>
                      <td>
                        <Link
                          to={{
                            pathname: `/edit-questao/${questao.id}`,
                            questao: {
                              id: questao.id,
                              titulo: questao.titulo
                            }
                          }}
                        >
                          {questao.titulo}
                        </Link>
                      </td>
                      <td className="d-flex justify-content-end">
                        <Link
                          to={{
                            pathname: `/edit-questao/${questao.id}`,
                            questao: {
                              id: questao.id,
                              titulo: questao.titulo
                            }
                          }}
                          className="btn btn-white btn-sm"
                          type="button"
                        >
                          <i className="far fa-bookmark mr-1" /> Editar questão
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
            <CardFooter style={{ borderTop: "1px #eee solid" }}>
              <div align="center" className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próximo"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </div>
            </CardFooter>
          </>
        ) : (
          <div className={`${styles.card}`}></div>
        )}
      </Card>
    );
  }
}

export default withRouter(QuestoesListsReprovado);
