import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  FormSelect,
  CardHeader,
  CardBody,
  CardFooter,
  FormCheckbox,
  InputGroupAddon,
  InputGroupText 
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import PageTitle from "../../../../../components/common/PageTitle";
import api from "../../../../../services/api";
import ReactPaginate from "react-paginate";
import "../../../../Turmas/styles.css";
import Select from "react-select";

const AlunoProva = props => {
  const [alunos, setAlunos] = useState([]);
  const [formData, setFormData] = useState({
    nome: "",
    email: ""
  });
  const [filteredData, setFilteredData] = useState([]);
  const [provaId, setProvaId] = useState();

  function constructor(props) {
    checkedAluno = checkedAluno.bind(this);
  }

  useEffect(() => {
    setProvaId(props.location.state)

    api
      .get("users/list-students")
      .then(res => {
        const data = res.data;

        setAlunos(data);
        setFilteredData(data);
      })
      .catch(function(error) {
        console.log(error.response);
      });
  }, []);

  const handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    const filteredData = alunos.filter(el =>
      el.name.toLowerCase().includes(lowerCasedValue)
    );

    setFilteredData(filteredData);
  };

  function pularEtapa() {
    props.history.push("/provas");
  }

  const selectedUser = [];

  const checkedAluno = event => {
    const { name, value } = event.target;
    if (selectedUser.length != 0) {
      selectedUser.forEach(element => {
        if (element === value) {
          var index = selectedUser.indexOf(value);
          selectedUser.splice(index, 1);
        } else {
          selectedUser.push({ user_id: value });
        }
      });
    } else {
      selectedUser.push({ user_id: value });
    }

    console.log(selectedUser);
  };

  function pularEtapa() {
    props.history.push("/provas");
  }

  async function handleSubmit() {
    const data = new FormData();
    data.append("alunos", JSON.stringify(selectedUser));
    data.append("prova_id", provaId);

    await api.post("/provas/user-provas", data).then(res => {
      props.history.push("/provas");
    });
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="10"
          lg="12"
          md="12"
          title="Cadastrar aluno à prova"
          subtitle="OVERVIEW"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="12" md="12">
          <Card small className="mb-3">
            <CardHeader style={{ borderBottom: "1px #eee solid" }}>
              <Row>
                <Col lg="8" md="8">
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Selecione os usuários
                  </label>
                </Col>
                <Col lg="4" md="4">
                  <InputGroup size="md" seamless>
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      placeholder="Pesquisar pelo nome do aluno"
                      style={{ fontSize: "14px" }}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form style={{ height: "240px", overflowY: "scroll" }}>
                {filteredData.map(aluno => (
                  <>
                    <div key={aluno.id} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        key={aluno.id}
                        value={aluno.id}
                        onChange={checkedAluno}
                      />
                      <label className="form-check-label" for="defaultCheck1">
                        {aluno.name}
                      </label>
                    </div>
                    <hr />
                  </>
                ))}
              </Form>
            </CardBody>
            <CardFooter style={{ borderTop: "1px #eee solid" }} align="right">
            <Button
                theme="primary"
                style={{ marginRight: "20px" }}
                onClick={() => pularEtapa()}
              >
                Pular etapa
              </Button>
              <Button theme="success" onClick={() => handleSubmit()}>
                Cadastrar alunos à prova
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(AlunoProva);
