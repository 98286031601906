import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import { createBrowserHistory } from "history";
import { DefaultLayout } from "./layouts";
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Questoes from "./pages/Questoes";
import Provas from "./pages/Provas";
import Prova from "./pages/Provas/Prova";
import CadastrarProva from "./pages/Provas/Prova/CadastrarProva";
import Upload from "./pages/frontend/Upload";
import SignIn from "./pages/SignIn";
import ListagemAlunos from "./pages/Alunos/ListagemAlunos";
import Aluno from "./pages/Provas/Aluno";
import Questao from "./pages/Questoes/Questao";
import CadastrarQuestao from "./pages/Questoes/Questao/CadastrarQuestao";
import CadastrarAluno from "./pages/Provas/Aluno/CadastrarAluno";
import Categorias from "./pages/Categorias";
import Usuarios from "./pages/Usuarios";
import CadastrarUser from "./pages/Usuarios/CreateUser";
import EditCategoria from "./pages/Categorias/EditCategoria";
import EditAluno from "./pages/Alunos/ListagemAlunos/EditAluno";
import EditUser from "./pages/Usuarios/EditUser";
import EditProva from "./pages/Provas/Prova/EditProva/EditProva";
import EditQuestao from "./pages/Questoes/Questao/EditQuestao";
import AlunoProva from "./pages/Provas/Prova/CadastrarProva/AlunoProva";
import ProvasReprovados from "./pages/ProvasReprovados";
import Documentacao from "./pages/Documentacao";
import EditProvaReprovado from "./pages/ProvasReprovados/EditProvaReprovado";
import ProvaReprovado from "./pages/ProvasReprovados/ProvaReprovado";
import Emails from "./pages/Emails";
import EditEmail from "./pages/Emails/EditEmail";
import AlunoReprovado from "./pages/ProvasReprovados/ProvaReprovado/AlunoReprovado";
import ProvaDocumentacao from "./pages/Documentacao/ProvasDocumentacao";
import CategoriasDocumentacao from "./pages/Documentacao/CategoriasDocumentacao";
import UsuariosDocumentacao from "./pages/Documentacao/UsuariosDocumentacao";
import RealizacaoProvaDocumentacao from "./pages/Documentacao/RealizacaoProvaDocumentacao";
import RealizacaoProvaReprovadoDocumentacao from "./pages/Documentacao/RealizacaoProvaReprovadoDocumentacao";
import RealizacaoEnvioDocDocumentacao from "./pages/Documentacao/RealizacaoEnvioDocDocumentacao";
import DashboardDocumentacao from "./pages/Documentacao/DashboardDocumentacao";
import ProvaReprovadoDocumentacao from "./pages/Documentacao/ProvaReprovadoDocumentacao";
import AlunosDocumentacao from "./pages/Documentacao/AlunosDocumentacao";

const history = createBrowserHistory({
  basename: "/"
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to="/sign-in"
          exact
          component={withTracker(props => {
            return <SignIn {...props} />;
          })}
        />
      )
    }
  />
);

class App extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      // if your component doesn't have to wait for an async action, remove this block
      return null; // render null when app is not ready
    }

    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <Switch>
          <PrivateRoute
            path="/"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <BlogOverview {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <BlogOverview {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/overview"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <BlogOverview {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/categorias"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Categorias {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/usuarios"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Usuarios {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/usuario-cadastro"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <CadastrarUser {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/user-detalhe/:id/edit"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditUser {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/provas-reprovados"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <ProvasReprovados {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/provas"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Provas {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/provas-reprovado/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <ProvaReprovado {...props} />
                </DefaultLayout>
              );
            })}
          />

          <PrivateRoute
            path="/provas-reprovados-edit/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditProvaReprovado {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/aluno/:id/:prova"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Aluno {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/alunos"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <ListagemAlunos {...props} />
                </DefaultLayout>
              );
            })}
          />

          <PrivateRoute
            path="/aluno-reprovado/:id/:prova"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <AlunoReprovado {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/aluno-cadastro"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <CadastrarAluno {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/cadastro/aluno-prova"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <AlunoProva {...props} />
                </DefaultLayout>
              );
            })}
          />

          <Route
            path="/prova/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Prova {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/prova-edit/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditProva {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/aluno-detalhe/:id/edit"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditAluno {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/categoria/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditCategoria {...props} />
                </DefaultLayout>
              );
            })}
          />

          <PrivateRoute
            path="/edit-questao/:id"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <EditQuestao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/prova-cadastro"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <CadastrarProva {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/questoes"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Questoes {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/questao"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Questao {...props} />
                </DefaultLayout>
              );
            })}
          />

          <PrivateRoute
            path="/:id/questao-cadastro"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <CadastrarQuestao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <Documentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-provas"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <ProvaDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-provas-reprovados"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <ProvaReprovadoDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-categorias"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <CategoriasDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />

          <PrivateRoute
            path="/documentacao-usuarios"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <UsuariosDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-realizacao-prova"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <RealizacaoProvaDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-realizacao-prova-reprovado"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <RealizacaoProvaReprovadoDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-realizacao-envio-doc"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <RealizacaoEnvioDocDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-dashboard"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <DashboardDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/documentacao-alunos"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <AlunosDocumentacao {...props} />
                </DefaultLayout>
              );
            })}
          />
          <PrivateRoute
            path="/user-profile"
            exact={true}
            history={history}
            component={withTracker(props => {
              return (
                <DefaultLayout {...props}>
                  <UserProfileLite {...props} />
                </DefaultLayout>
              );
            })}
          />

          <Route
            path="/sign-in"
            exact={true}
            history={history}
            component={withTracker(props => {
              return <SignIn {...props} />;
            })}
          />
          <Route
            path="*"
            component={withTracker(props => {
              return <Errors {...props} />;
            })}
          />
        </Switch>
      </Router>
    );
  }
}

function demoAsyncCall() {
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
}

export default App;
