import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter, 
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox,
  Alert
} from "shards-react";
import PageTitle from "../../../../components/common/PageTitle";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../services/api";
import Select from "react-select";


const CadastrarQuestao = props => {
  const [inputList, setInputList] = useState([{ correta: "0", questao: "" }]);
  const [categorias, setCategorias] = useState([]);
  const [multiValue, setMultiValue] = useState([]);
  const [provaId, setProvaId] = useState([]);
  const [formData, setFormData] = useState({
    titulo: ""
  });
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRadioChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  }; 

  useEffect(() => {
    if (props.location.prova === undefined) {
      props.history.push("/provas");
    } else {
      api
        .get("categorias/list-all")
        .then(response => {
          setCategorias(response.data);
        })
        .catch(function(error) {
          console.log(error.response);
        });

      setProvaId(props.location.prova.id);
    }
  }, []);

  function handleMultiChange(option) {
    setMultiValue(option);
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { correta: "0", questao: "" }]);
  };

  function handleInputedChange(event) {
    const { name, value } = event.target;
    setFormData({ ...FormData, [name]: value });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    const { titulo } = formData;

    const data = new FormData();

    data.append("titulo", titulo);
    data.append("alternativas", JSON.stringify(inputList));
    data.append("categorias", JSON.stringify(multiValue));
    data.append("valor", "2");
    data.append("prova_id", props.match.params.id);
    await api
      .post("questoes/register-questao", data)
      .then(res => {
        props.history.push(`/prova/${props.match.params.id}`)
      })
      .catch(errors => {
        console.log(errors);
      });
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="12"
          title="Cadastrar questão"
          subtitle="Detalhe da questão"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="12" md="12">
          <Card small className="mb-4">
            <Form onSubmit={handleSubmit}>
              <CardHeader className="border-bottom">
                <h6 className="m-0">Questão</h6>
              </CardHeader>
              <CardBody className="pb-3">
                <FormGroup>
                  <label htmlFor="username" style={{ fontWeight: "600" }}>
                    Título da questão
                  </label>
                  <FormInput
                    className="form-control"
                    name="titulo"
                    id="username"
                    type="text"
                    onChange={handleInputedChange}
                  />
                </FormGroup>

                <FormGroup>
                  <label htmlfor="select" style={{ fontWeight: "600" }}>
                    Categorias
                  </label>
                  <Select
                    options={categorias}
                    onChange={handleMultiChange}
                    isMulti
                  />
                </FormGroup>

                <FormGroup>
                  <label style={{ fontWeight: "600" }}>Opções</label>
                  {inputList.map((x, i) => {
                    return (
                      <div className="box">
                        <FormGroup>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <div class="input-group-text">
                                <input
                                  type="radio"
                                  aria-label="Radio button for following text input"
                                  name="correta"
                                  value="1"
                                  onChange={e => handleRadioChange(e, i)}
                                />
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Recipient's username"
                              aria-label="Recipient's username"
                              aria-describedby="button-addon2"
                              name="questao"
                              placeholder="Insira a opção"
                              value={x.firstName}
                              onChange={e => handleInputChange(e, i)}
                            />
                            {inputList.length !== 1 && (
                              <div class="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  id="button-addon2"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remover
                                </button>
                              </div>
                            )}
                          </div>
                        </FormGroup>
                        <div className="btn-box">
                          {inputList.length - 1 === i && (
                            <Button theme="success" onClick={handleAddClick}>
                              Adicionar alternativa
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </FormGroup>
              </CardBody>
              <CardFooter style={{ borderTop: "1px #eee solid" }}>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6" align="right">
                    <Button size="sm" theme="white" type="submit">
                      <i className="far fa-bookmark mr-1" />
                      Cadastrar questão
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(CadastrarQuestao);
