import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardHeader,
  ButtonToolbar,
  ButtonGroup,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  FormCheckbox
} from "shards-react";
import { Link, useHistory, withRouter } from "react-router-dom";
import api from "../../../../../services/api";

const AdicionarAlternativa = props => {
  const [inputList, setInputList] = useState([
    { correta: "0", questao: "", id: "" }
  ]);
  const [categorias, setCategorias] = useState([]);
  const [alternativas, setAlternativas] = useState([]);
  const [multiValue, setMultiValue] = useState([]);
  const [id, setId] = useState([]);
  const [idCategoria, setIdCategoria] = useState([]);
  const [formData, setFormData] = useState({
    titulo: ""
  });

  const [formDataInput, setFormDataInput] = useState({
    texto: ""
  });

  const [novaAlternativa, setNovaAlternativa] = useState(false);

  // handle click event of the Add button
  const handleAddClick = () => {
    setNovaAlternativa(true);
    setInputList([...inputList, { correta: "0", questao: "", id: "" }]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index]["id"] = index;
    setInputList(list);
    props.salveiFilho(JSON.stringify(inputList));
  };

  const handleRadioChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = 1;
    setInputList(list);

    props.salveiFilho(JSON.stringify(inputList));
  };

  // handle click event of the Remove button
  const handleRemoveClick = e => {
    const list = [...inputList];
    if (e.target.id === "0") {
      list.shift()
    } else {
      list.splice(e.target.id, 1);
    }
    setInputList(list);
  };

  const { titulo } = formData;

  const handleSetTrue = index => {
    setNovaAlternativa(true);
  };

  return (
    <>
      {inputList.map((x, i) => {
        return (
          <div className="box">
            {novaAlternativa === true && (
              <FormGroup>
                <>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <input
                          id={i}
                          type="radio"
                          aria-label="Radio button for following text input"
                          name="correta"
                          onChange={e => handleRadioChange(e, i)}
                          checked={x.correta === 1}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Recipient's username"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      name="questao"
                      id={i}
                      value={x.firstName}
                      onChange={e => handleInputChange(e, i)}
                    />
                    <div class="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id={i}
                        onClick={handleRemoveClick}
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </>
              </FormGroup>
            )}
            {novaAlternativa === true && (
              <div className="btn-box">
                {inputList.length - 1 === i && (
                  <Button theme="success" onClick={handleAddClick}>
                    Adicionar alternativa
                  </Button>
                )}
              </div>
            )}
            {novaAlternativa === false && (
              <div className="btn-box">
                {inputList.length - 1 === i && (
                  <Button theme="success" onClick={handleSetTrue}>
                    Adicionar alternativa
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default withRouter(AdicionarAlternativa);
