import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormInput,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Tooltip,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Alert
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import api from "../../services/api";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../Turmas/styles.css";
import { CircleSpinner } from "react-spinners-kit";
import styles from "./styles.module.css";

class Categorias extends React.Component {
  state = {
    categorias: [],
    open: false,
    nome: "",
    filteredData: [],
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    message: "",
    theme: "",
    loading: false,
    loadingCadastro: false,
    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 1,
    pageCount: 0
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onChangeNome = this.onChangeNome.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.clearInterval = this.clearInterval.bind(this);
    this.interval = null;
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handleSearchChange = e => {
    const { value } = e.target;
    const lowerCasedValue = value.toLowerCase();

    this.setState(prevState => {
      const filteredData = prevState.categorias.filter(el =>
        el.nome.toLowerCase().includes(lowerCasedValue)
      );

      return { filteredData };
    });
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  async receivedData() {
    this.setState({
      loading: true
    });

    await api
      .get("categorias", {
        credentials: 'include'
      })
      .then(res => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        this.setState({
          categorias: slice,
          filteredData: slice,
          pageCount: Math.ceil(data.length / this.state.perPage),
          loading: false
        });
      })
      .catch(error => {
        console.log(error.response);
        this.setState(
          {
            //message: "Houve um erro na requisição, tente novamente.",
            //theme: "danger",
            loading: false
          }/*,
          () => {
            this.showAlert();
          }*/
        );
      });
  }

  async componentDidMount() {
    this.receivedData();
    if (this.props.location.state === true) {
      this.setState({
        message: "Categoria alterada com sucesso.",
        theme: "success"
      });
      this.showAlert();
    }
  }

  onChangeNome(e) {
    e.preventDefault();
    this.setState({
      nome: e.target.value
    });
  }

  async handleSubmit() {
    this.setState({
      loadingCadastro: true
    });
    const data = new FormData();
    data.append("nome", this.state.nome);
    await api
      .post("categorias", data, {
        credentials: 'include'
      })
      .then(res => {
        this.setState({
          message: "Categoria cadastrada com sucesso.",
          theme: "success"
        });
        this.receivedData();
        this.setState({
          open: false
        });
        this.showAlert();
        this.setState({
          loadingCadastro: false
        });
      })
      .catch(error => {
        this.setState(
          {
            message: "Ops! Algo saiu errado, tente novamente.",
            theme: "danger"
          },
          () => {
            this.showAlert();
          }
        );

        this.setState({
          loadingCadastro: false
        });
      });
  }

  showAlert() {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  }

  handleTimeChange() {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 }
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  }

  clearInterval() {
    clearInterval(this.interval);
    this.interval = null;
    this.props.location.alert = false;
  }

  handlePageClick = e => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedData();
      }
    );
  };

  render() {
    const { categorias, loading, loadingCadastro } = this.state;
    const { open } = this.state;
    const { filteredData } = this.state;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            lg="12"
            md="12"
            title="Categorias"
            subtitle="Categorias cadastradas no sistema"
            className="text-sm-left"
          />
        </Row>

        <Row>
          <Col lg="12" md="12">
            <div>
              <Alert
                className="mb-3"
                open={this.state.visible}
                theme={this.state.theme}
              >
                {this.state.message}
              </Alert>
            </div>
          </Col>
          <Col lg="12" md="12">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <div className="row">
                  <div className="col-md-4 col-4">
                    <h6 className="m-0">Categorias</h6>
                  </div>
                  <div className="col-md-8 col-8 d-flex justify-content-end">
                    <div className="row">
                      <div className="col-md-6 col-6">
                        <InputGroup size="md" seamless>
                          <InputGroupAddon type="prepend">
                            <InputGroupText>
                              <i className="fa fa-search"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            placeholder="Pesquisar pelo nome da categoria"
                            style={{ fontSize: "14px" }}
                            onChange={this.handleSearchChange}
                          />
                        </InputGroup>
                      </div>
                      <div className="col-md-6 col-6">
                        <ButtonToolbar>
                          <ButtonGroup size="sm">
                            <Button onClick={this.toggle}>
                              <i className="material-icons">
                                add_circle_outline
                              </i>{" "}
                              Cadastrar nova categoria
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              {loading === false ? (
                <>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            #
                          </th>
                          <th scope="col" className="border-0">
                            Categoria
                          </th>
                          <th scope="col" className="border-0"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((categoria, index) => (
                          <tr key={categoria.id}>
                            <td>{index + 1}</td>
                            <td>{categoria.nome}</td>
                            <td className="d-flex justify-content-end">
                              <Link
                                to={{
                                  pathname: `categoria/${categoria.id}`,
                                  categoria: {
                                    nome: categoria.nome,
                                    id: categoria.id
                                  }
                                }}
                                className="btn btn-white btn-sm"
                                type="button"
                              >
                                <i className="far fa-bookmark mr-1" /> Editar
                                categoria
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter style={{ borderTop: "1px #eee solid" }}>
                    <div
                      align="center"
                      className="d-flex justify-content-center"
                    >
                      <ReactPaginate
                        previousLabel={"Anterior"}
                        nextLabel={"Próximo"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={this.state.currentPage ? "active" : ""}
                      />
                    </div>
                  </CardFooter>
                </>
              ) : (
                <div className={`${styles.card}`}></div>
              )}
            </Card>
          </Col>
        </Row>
        <Modal open={open} toggle={this.toggle}>
          <ModalHeader>Cadastrar categoria</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <label htmlFor="#username">Nome</label>
                <FormInput
                  id="#username"
                  placeholder="Nome"
                  onChange={this.onChangeNome}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              theme="primary"
              size="sm"
              disabled={loadingCadastro === true}
              onClick={this.handleSubmit}
            >
              {loadingCadastro === true && (
                <>
                  <div className="row">
                    <div className="col-auto" style={{ paddingLeft: "8px" }}>
                      <CircleSpinner
                        size={20}
                        color="#fff"
                        loading={loadingCadastro}
                      ></CircleSpinner>
                    </div>
                    <div className="col">
                      <span
                        style={{ paddingTop: "4px", verticalAlign: "middle" }}
                      >
                        Cadastrando categoria...
                      </span>
                    </div>
                  </div>
                </>
              )}{" "}
              {loadingCadastro === false && <>Cadastrar categoria</>}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default Categorias;
